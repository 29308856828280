<div class="filters">
  <div class="form-group">
    <label class="form-label" for="statusFilter">Status</label>
    <ng-select
      [items]="statusOptions"
      bindLabel="status"
      [(ngModel)]="statusFilter"
      [searchable]="false"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="typeFilter">Type</label>
    <ng-select [items]="typeOptions" bindLabel="type" [(ngModel)]="typeFilter" [searchable]="false" placeholder="All" (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="affiliateFilter">Affiliate</label>
    <ng-select
      [items]="affiliateOptions"
      bindLabel="affiliate"
      [(ngModel)]="affiliateFilter"
      [searchable]="true"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="pillarFilter">Pillar</label>
    <ng-select
      [items]="pillarOptions"
      bindLabel="pillar"
      [(ngModel)]="pillarFilter"
      [searchable]="false"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="responsibleFilter">Responsible</label>
    <ng-select
      [items]="responsibleOptions"
      bindLabel="responsible"
      [(ngModel)]="responsibleFilter"
      [searchable]="true"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="deadlineFilter">Deadline</label>
    <input class="form-control" type="date" [(ngModel)]="deadlineFilter" (change)="filterActionPlans()" placeholder="Filter by deadline" />
    <mat-datepicker #picker></mat-datepicker>
  </div>

  <button class="btn btn-link" (click)="resetFilters()" matTooltip="Reset the filters">
    <span class="material-icons" style="color: red">filter_list_off</span>
  </button>
</div>

<table class="table" id="action-plan-table">
  <caption>
    Action Plan
  </caption>
  <thead>
    <tr>
      <th scope="col">Status</th>
      <th scope="col">Type</th>
      <th scope="col">Action / Decision / Info</th>
      <th scope="col">Creation Date</th>
      <th scope="col">Comments & Affiliate <span class="affiliate-tag-title">(&#64; to tag an affiliate)</span></th>
      <th scope="col">Pillars</th>
      <th scope="col">Responsible</th>
      <th scope="col">Deadline</th>
      <th scope="col" *ngIf="isWriter || isAdmin" class="no-export"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let actionPlan of filteredActionPlans">
      <td>
        <span
          class="material-icons-outlined status clickable"
          [ngStyle]="{color: getStatusColor(actionPlan.status)}"
          [title]="actionPlan.status"
          [matTooltip]="actionPlan.status"
          [matMenuTriggerFor]="menuStatus"
          *ngIf="actionPlan.status !== 'Non Applicable'">
          {{ getStatusIcon(actionPlan.status) }}
        </span>
        <span
          class="material-icons-outlined status non-clickable"
          [ngStyle]="{color: getStatusColor(actionPlan.status)}"
          [title]="actionPlan.status"
          [matTooltip]="actionPlan.status"
          *ngIf="actionPlan.status === 'Non Applicable'">
          {{ getStatusIcon(actionPlan.status) }}
        </span>

        <!-- Menu to Select New Status -->
        <mat-menu #menuStatus="matMenu" class="menu-status" xPosition="after" yPosition="below">
          <button
            mat-menu-item
            *ngFor="let status of ['To do', 'In progress', 'Blocked', 'Done']"
            [ngClass]="{selected: actionPlan.status === status}"
            (click)="changeActionPlanStatus(actionPlan, status)"
            [matTooltip]="status"
            matTooltipPosition="right">
            <span class="material-icons-outlined status-icon" [ngStyle]="{color: getStatusColor(status)}">
              {{ getStatusIcon(status) }}
            </span>
          </button>
        </mat-menu>
      </td>
      <td>{{ actionPlan.type }}</td>
      <td>{{ actionPlan.title }}</td>
      <td>
        {{ actionPlan.committee != "No committee" && actionPlan.committee != null ? actionPlan.committee + " : " : ""
        }}{{ formatDate(actionPlan.creationDate) }}
      </td>
      <td>
        <span
          *ngFor="let part of formatComment(actionPlan.comment)"
          [ngStyle]="{
            color: isAffiliate(part) ? '#285aff' : 'initial'
          }">
          {{ part }}
        </span>
      </td>
      <td>{{ actionPlan.pillarName }}</td>
      <td>{{ actionPlan.responsible.fullName }}</td>
      <td>{{ formatDate(actionPlan.deadline) }}</td>
      <td *ngIf="isWriter || isAdmin" class="no-export">
        <button mat-icon-button [matMenuTriggerFor]="menuAction" (click)="setCurrentAction(actionPlan)">
          <span class="material-icons">more_horiz</span>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<mat-menu #menuAction="matMenu">
  <button mat-menu-item (click)="editAction(currentAction.id)">Edit Action</button>
  <button mat-menu-item (click)="deleteAction(currentAction.id)">Delete Action</button>
</mat-menu>
