import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../common/authentication.service";
import {SharedEventsService} from "../common/shared-events-service";
import {ProductDTO, UserService} from "../common/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userProducts: ProductDTO[] = [];
  selectedProductId: number;
  temporaryProduct: ProductDTO | null = null;
  public username: string;

  constructor(
    public authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedEventsService: SharedEventsService
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.userIsAuthenticated) {
      this.username = this.authenticationService.username;
      this.loadUserProducts();

      this.sharedEventsService.reloadHeader$.subscribe(() => {
        this.loadUserProducts();
      });

      this.sharedEventsService.selectedProduct$.subscribe((product) => {
        if (!this.userProducts.some((userProduct) => userProduct.id === product.id)) {
          this.temporaryProduct = product;
        }
      });
    }
  }

  loadUserProducts() {
    this.userService.getProductsForUserWithEditRights(this.authenticationService.email).subscribe(
      (products) => {
        this.userProducts = products;

        if (products.length === 0) {
          this.router.navigate(["/search"]);
          return;
        }

        // Redirect to first product if no route active
        if (products.length > 0 && !this.isOnProductRoute()) {
          const firstProduct = products[0];
          this.router.navigate(["/product", firstProduct.name, firstProduct.id]); // Redirect to first product
          this.selectedProductId = firstProduct.id;
        }
      },
      (error) => {
        console.error("Error when getting the products :", error);
      }
    );
  }

  onProductSelect(productId: number): void {
    this.selectedProductId = productId;
    this.temporaryProduct = null;
  }

  onSearchClick(): void {
    this.temporaryProduct = null;
  }

  // Check if user is already seeing a product
  isOnProductRoute(): boolean {
    const currentRoute = this.route.snapshot.firstChild; // Get active route
    // Check if the active route is a 'product' route
    return currentRoute?.routeConfig?.path?.includes("product") || currentRoute?.routeConfig?.path?.includes("search") || false;
  }
}
