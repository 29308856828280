<popup-header [dialogRef]="dialogRef" icon="{{ data.icon }}" text="{{ data.title }}"></popup-header>
<div class="popup-container">
  <div class="text-container">
    {{ data.text }}
  </div>
  <div class="buttons-container">
    <button type="button" class="btn btn-secondary" (click)="close('cancel')">No</button>
    <button type="button" class="btn btn-primary" (click)="close('confirm')">Yes</button>
  </div>
</div>
