<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" id="accordion-btn-header-highlights">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#accordion-btn-collapse-highlights"
        aria-expanded="true"
        aria-controls="accordion-btn-collapse-highlights">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true"> devices_fold</span>
          Highlights
        </div>
      </button>
      <div class="container-right">
        <span class="last-update-text">Last Update: {{ lastUpdateHighlights | date : "dd/MM/yyyy":"UTC" }}</span>
      </div>
    </h4>

    <div id="accordion-btn-collapse-highlights" class="accordion-collapse collapse show" aria-labelledby="accordion-btn-header-highlights">
      <div class="accordion-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Date</th>
              <th scope="col" class="text-center" *ngIf="isWriter || isAdmin">
                <button type="button" class="btn btn-outline btn-circle" (click)="startAdding()" [disabled]="isAdding" title="Add Highlight">
                  <span class="material-icons-outlined">add</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Adding new highlight -->
            <tr *ngIf="isAdding">
              <td>
                <textarea type="text" class="form-control" [(ngModel)]="newHighlight.description" placeholder="Enter description"></textarea>
              </td>
              <td>{{ newHighlight.creationDate | date : "dd/MM/yyyy":"UTC" }}</td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-outline btn-circle btn-tertiary color_blue"
                  (click)="saveNewHighlight()"
                  [disabled]="saveInProgress">
                  <span class="material-icons-outlined">check</span>
                </button>
                <button type="button" class="btn btn-sm btn-tertiary btn-circle" (click)="cancelAdding()">
                  <span class="material-icons-outlined">close</span>
                </button>
              </td>
            </tr>

            <!-- Existing highlights -->
            <tr *ngFor="let highlight of highlights">
              <td *ngIf="!highlight.isEditing" [innerHTML]="formatDescription(highlight.description)"></td>

              <td *ngIf="highlight.isEditing">
                <textarea type="text" class="form-control" [(ngModel)]="highlight.description"></textarea>
              </td>
              <td>{{ highlight.creationDate | date : "dd/MM/yyyy":"UTC" }}</td>
              <td class="text-center" *ngIf="isWriter || isAdmin">
                <button
                  *ngIf="!highlight.isEditing"
                  type="button"
                  class="btn btn-outline-primary btn-tertiary btn-circle"
                  (click)="startEditing(highlight)"
                  title="Edit Highlight">
                  <span class="material-icons-outlined">edit</span>
                </button>
                <button
                  *ngIf="highlight.isEditing"
                  type="button"
                  class="btn btn-sm btn-success btn-tertiary"
                  (click)="saveEditedHighlight(highlight)"
                  [disabled]="saveInProgress"
                  title="Save Changes">
                  <span class="material-icons-outlined">check</span>
                </button>
                <button
                  *ngIf="highlight.isEditing"
                  type="button"
                  class="btn btn-sm btn-tertiary"
                  (click)="cancelEditing(highlight)"
                  title="Cancel Editing">
                  <span class="material-icons-outlined">close</span>
                </button>
                <button
                  *ngIf="!highlight.isEditing"
                  type="button"
                  class="btn btn-sm btn-danger btn-tertiary btn-circle"
                  (click)="deleteHighlight(highlight.id)"
                  title="Delete Highlight">
                  <span class="material-icons-outlined">delete</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
