import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "confirm-popup",
    templateUrl: "./confirm-popup.component.html",
    styleUrls: ["./confirm-popup.component.scss"],
})
export class ConfirmPopupComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    close(result: string) {
        this.dialogRef.close(result);
    }
}

