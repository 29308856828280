import {Component, Input, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ActionPlanComponent} from "../action-plan/action-plan.component";
import {ManageActionComponent} from "../action-plan/manage-action/manage-action.component";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {EightPillarsService} from "./eight-pillars.service";
import {PillarCommentComponent} from "./pillar-comment/pillar-comment.component";
import {PillarCommentService} from "./pillar-comment/pillar-comment.service";
import {PillarCommentDTO, PillarDTO} from "./pillar-dto";
@Component({
  selector: "eight-pillars",
  templateUrl: "./eight-pillars.component.html",
  styleUrls: ["./eight-pillars.component.scss"],
})
export class EightPillarsComponent implements OnChanges {
  @Input() selectedProductId!: number;
  @Input() selectedProductName: string;
  @Input() selectedProductOwners: Array<string>;

  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;

  selectedSegment: number = 0;
  lastUpdatePillars: Date;
  lastUpdateActionPlan: Date;
  @Input() section!: string;
  @ViewChild(ActionPlanComponent) actionPlanComponent: ActionPlanComponent;
  pillars: PillarDTO[];

  constructor(
    private eightPillarsService: EightPillarsService,
    private dialog: MatDialog,
    private pillarCommentService: PillarCommentService,
    private toastrService: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedProductId?.currentValue) {
      this.loadPillars();
    }
  }

  loadPillars() {
    this.eightPillarsService.getAllPillarsByProduct(this.selectedProductId).subscribe((res: PillarDTO[]) => {
      this.pillars = res;

      this.getLastUpdateDate();
    });
  }

  getLastUpdateDate() {
    let lastUpdateDate: Date;
    this.pillars.forEach((pillar) => {
      if (lastUpdateDate == null || pillar.lastUpdateDate > lastUpdateDate) lastUpdateDate = pillar.lastUpdateDate;
    });
    this.lastUpdatePillars = lastUpdateDate;
  }

  addComment(pillarCardId: number) {
    this.dialog
      .open(PillarCommentComponent, {
        width: "40vw",
        data: {
          pillarCardId: pillarCardId,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadPillars();
        }
      });
  }

  editComment(pillarCardComment: PillarCommentDTO) {
    this.dialog
      .open(PillarCommentComponent, {
        width: "40vw",
        data: {
          pillarCardComment: pillarCardComment,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadPillars();
        }
      });
  }

  deleteComment(commentId: number) {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete a comment",
          text: "Are you sure you want to delete this comment ?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "confirm") {
          this.pillarCommentService.deleteComment(commentId).subscribe(
            () => {
              this.toastrService.success("Comment has been deleted !");
              this.loadPillars();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the comment.");
            }
          );
        }
      });
  }

  changePillarStatus(pillar: PillarDTO, status: string) {
    if (pillar.status != status) {
      this.eightPillarsService.changePillarStatus(pillar.id, status).subscribe(
        () => {
          this.toastrService.success("Status has been updated !");
          this.loadPillars();
        },
        () => {
          this.toastrService.error("An error occurred while updating the status.");
        }
      );
    }
  }

  getStatusDescriptionFromColor(status: string): string {
    if (status == "green") return "Secured";
    else if (status == "orange") return "Under control | Close follow-up";
    else if (status == "red") return "Critical point";
  }

  /**ACTION PLAN **/
  openAddActionDialog(): void {
    const dialogRef = this.dialog.open(ManageActionComponent, {
      data: {productId: this.selectedProductId},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshActionPlan();
      }
    });
  }

  refreshActionPlan() {
    if (this.actionPlanComponent) {
      this.actionPlanComponent.loadActionPlansByProductId(this.selectedProductId);
    }
  }

  exportInXlsx() {
    this.actionPlanComponent.exportInXlsx();
  }

  exportInJpeg() {
    this.actionPlanComponent.exportInJpeg();
  }

  exportInPdf() {
    this.actionPlanComponent.exportInPdf();
  }
}
