import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class PillarCommentService {
    private apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/pillars`;

    constructor(private http: HttpClient) {
    }

    savePillarComment(commentId: number, comment: string, status: string, pillarCardId: number): Observable<any> {
        let httpParams: HttpParams = new HttpParams();
        if (commentId != null) httpParams = httpParams.set("commentId", commentId);
        httpParams = httpParams.set("status", status);
        if (pillarCardId != null) httpParams = httpParams.set("pillarCardId", pillarCardId);

        return this.http.post<any>(this.apiUrl + "/save-pillar-comment", comment, {
            params: httpParams
        });
    }

    deleteComment(commentId: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + "/delete-comment", {
            params: {
                "commentId": commentId
            }
        })
    }
}

