import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ToolboxService} from "./toolbox.service";
import {MatDialog} from "@angular/material/dialog";
import {AddToolboxItemComponent} from "./add-toolbox-item/add-toolbox-item.component";
import {EditDeleteToolboxItemComponent} from "./edit-delete-toolbox-item/edit-delete-toolbox-item.component";
import {saveAs} from "file-saver";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: "toolbox",
  templateUrl: "./toolbox.component.html",
  styleUrls: ["./toolbox.component.scss"],
})
export class ToolboxComponent implements OnChanges {
  @Input() selectedProductId!: number;

  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;
  @Input() section!: string;

  productLinks: Array<any>;
  productDocuments: Array<any>;

  constructor(private toolboxService: ToolboxService, private dialog: MatDialog, private toastrService: ToastrService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProductId?.currentValue) {
      this.loadToolbox();
    }
  }

  loadToolbox() {
    this.toolboxService.loadToolbox(this.selectedProductId).subscribe((res) => {
      this.productLinks = res.productLinks;
      this.productDocuments = res.productDocuments;
    });
  }

  addItem(type: string) {
    this.dialog
      .open(AddToolboxItemComponent, {
        width: "40vw",
        data: {
          productId: this.selectedProductId,
          alreadyExistingPredefinedItems: type == "link" ? this.productLinks.map(productLink => productLink.title) : this.productDocuments.map(productDocument => productDocument.title),
          type: type
        }
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadToolbox();
        }
      });
  }

  modifyItem(type: string, deleteMode: boolean) {
    this.dialog
      .open(EditDeleteToolboxItemComponent, {
        width: "40vw",
        data: {
          productId: this.selectedProductId,
          existingItems: type == "link" ? this.productLinks : this.productDocuments,
          type: type,
          delete: deleteMode
        }
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadToolbox();
        }
      });
  }

  getIconForLink(link: string): string {
    switch (link) {
      case "Confluence":
        return "drive_folder_upload";
      case "Sharepoint":
        return "outbox";
      case "Dev":
        return "code";
      case "Q/A":
        return "task_alt";
      case "Prod":
        return "factory";
      default:
        return null;
    }
  }

  getDocument(document: any) {
    let filename = document.documentPath.split("/")[document.documentPath.split("/").length - 1];

    this.toolboxService.getDocument(document.documentPath).subscribe(
      (file) => {
        let resp: Blob = new Blob([file], {
          endings: "transparent",
        });

        saveAs(resp, filename);
      },
      () => {
        this.toastrService.error("Failed to get the document " + document.title);
      }
    );
  }
}
