import {Component, OnInit} from "@angular/core";
import {Meta} from "@angular/platform-browser";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {EventMessage, EventType} from "@azure/msal-browser";
import {filter} from "rxjs/operators";
import {environment} from "../environments/environment";
import {AppInsightsService} from "./common/app-insights.service";
import {AuthenticationService} from "./common/authentication.service";
import {UserService} from "./common/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "deploy-cockpit";
  public userExist = false;
  userData: any;
  initialized = false;

  contentSecurityPolicy = {
    "default-src": `'none'`,
    "frame-src": `https://login.microsoftonline.com http://localhost:4200`,
    "img-src": `'self' blob: data: https://*.hotjar.com https://*.hotjar.io`,
    "script-src": `'self' https://*.hotjar.com`,
    "script-src-elem": `'self' https://*.hotjar.com https://*.hotjar.io https://*.zscalertwo.net 'unsafe-inline'`,
    "style-src": `'self' 'unsafe-inline' https://fonts.googleapis.com https://*.hotjar.com`,
    "style-src-elem": `'self' https://fonts.googleapis.com/ 'unsafe-inline' https://unpkg.com`,
    "font-src": `data: https://fonts.gstatic.com https://*.hotjar.com https://*.hotjar.io`,
    "connect-src": `'self' data: ${environment.serverUrl} https://login.microsoftonline.com https://graph.microsoft.com https://dc.services.visualstudio.com https://fonts.googleapis.com https://fonts.gstatic.com https://*.hotjar.com:* https://*.hotjar.io wss://*.hotjar.com`,
  };

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private meta: Meta,
    private appInsightsService: AppInsightsService,
    public authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.meta.addTags([
      {
        "http-equiv": "Content-Security-Policy",
        content: Object.keys(this.contentSecurityPolicy)
          .map((key) => `${key} ${this.contentSecurityPolicy[key]}`)
          .join(";"),
      },
    ]);
  }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)).subscribe(() => {});

    // On login success or acquire token success
    // init the existance of the user to avoid the loading of app-menu component before the end of the authentication
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.HANDLE_REDIRECT_END
        )
      )
      .subscribe(async () => {
        if (!this.userExist) {
          const user = this.authService.instance.getAllAccounts()[0];
          this.userExist = !!user;
          if (user) {
            this.appInsightsService.setAuthenticatedUser(user.username, user.localAccountId);
            await this.authenticationService.initializeAuthentication();
            if (this.authenticationService.userIsAuthenticated) {
              const userIsAdmin = this.authenticationService.userIsAdmin;
              this.userService.setUserIsAdmin(userIsAdmin);
              this.initialized = true;
            }
          } else {
            this.authService.loginRedirect();
          }
        }
      });
  }
}
