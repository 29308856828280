<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" id="accordion-btn-header-usage">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#accordion-btn-collapse-usage"
        aria-expanded="true"
        aria-controls="accordion-btn-collapse-usage">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true">feedback</span>
          Product Usage & Feedback from End-Users
        </div>
      </button>
      <div class="container-right">
        <a *ngIf="productNameInUsageMonitoring" href="https://aiousagemonitoringfront.z6.web.core.windows.net/users-count/{{productNameInUsageMonitoring}}" target="_blank" class="usage-link">
          <span class="material-icons-outlined" matTooltip="Go to Deploy Usage Monitoring">launch</span>
        </a>
      </div>
    </h4>

    <div id="accordion-btn-collapse-usage" class="accordion-collapse collapse show" aria-labelledby="accordion-btn-header-usage">
      <div class="accordion-body">
        <ng-container>
          <div *ngIf="hasData" class="noMarginRightLeft users-count">
            <div>
              <div class="action-menu-container">
                <div>
                  <div style="display: inline-block; margin-right: 15px">Show count :</div>
                  <mat-button-toggle-group name="connections" [(ngModel)]="durationSelected" (ngModelChange)="getUsersCount()">
                    <mat-button-toggle value="7d">Weekly</mat-button-toggle>
                    <mat-button-toggle value="1month">Monthly</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div>
                  <button class="btn btn-outline-primary btn-circle zoom-btn" (click)="zoomIn()">
                    <span class="material-icons-outlined">add_circle_outline</span>
                  </button>
                  <button class="btn btn-outline-primary btn-circle zoom-btn" (click)="zoomOut()">
                    <span class="material-icons-outlined">remove_circle_outline</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div [style]="hasData ? 'height: 50vh' : ''">
            <canvas [style]="hasData ? '' : 'height:0'" id="usersCount"></canvas>
          </div>
        </ng-container>
        <span *ngIf="!hasData" style="display: flex; justify-content: center; font-style: italic;">No Data (this product may not exist on Deploy Usage Monitoring).</span>
      </div>
    </div>
  </div>
</div>
