import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {UserDTO} from "../common/user.service";

export interface ActionPlan {
  id: number;
  status: string;
  type: string;
  title: string;
  creationDate: Date;
  comment: string;
  pillarName: string;
  responsible: UserDTO;
  deadline: Date;
  pillarCard: {id: number};
  committee: string;
}

export interface ActionPlanRes {
  actionPlans: ActionPlan[];
  actionPlanLastUpdateDate: Date;
}

@Injectable({
  providedIn: "root",
})
export class ActionPlanService {
  private apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/action-plan`;

  constructor(private http: HttpClient) {}

  getActionPlansByProductId(productId: number): Observable<ActionPlanRes> {
    return this.http.get<ActionPlanRes>(`${this.apiUrl}/${productId}`);
  }

  createAction(actionData: ActionPlan): Observable<ActionPlan> {
    return this.http.post<ActionPlan>(`${this.apiUrl}`, actionData);
  }

  updateAction(actionId: number, actionData: ActionPlan): Observable<ActionPlan> {
    return this.http.post<ActionPlan>(`${this.apiUrl}/update/${actionId}`, actionData);
  }

  deleteAction(actionId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/delete/${actionId}`);
  }

  changeActionPlanStatus(actionPlanId: number, status: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/change-action-plan-status", {
      params: {
        actionPlanId: actionPlanId,
        status: status,
      },
    });
  }
}
