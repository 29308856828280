<nav class="navbar navbar-expand-sm flex-column">
  <!-- Accessibility link -->
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <a class="navbar-brand text-primary" href="#">
      <img src="assets/logo_deploy_cockpit.svg" alt="" />
    </a>
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center">Deploy Cockpit</span>
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <li class="nav-item d-none d-xl-block">
            <form class="d-flex">
              <input class="form-control" type="search" placeholder="Search" aria-label="Search" />
              <button class="btn btn-outline-primary btn-circle">
                <span class="material-icons-outlined">search</span>
              </button>
            </form>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-primary btn-circle">
              <span class="material-icons-outlined">notifications</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-primary btn-circle">
              <span class="material-icons-outlined">settings</span>
            </button>
          </li>
          <li class="nav-item ms-md-4">
            <span class="navbar-text text-uppercase">{{ username }} </span>
            <button class="btn btn-outline-primary btn-circle" [matMenuTriggerFor]="popover.profileMenu">
              <span class="material-icons-outlined">account_circle</span>
            </button>
            <app-popover #popover></app-popover>
          </li>
        </ul>
      </div>
      <hr class="separator" />
      <div class="container-fluid nav-dpr-container">
        <!-- Tab menu -->
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngIf="temporaryProduct">
            <a
              class="nav-link active"
              [routerLink]="['/product', temporaryProduct.name, temporaryProduct.id]"
              (click)="onProductSelect(temporaryProduct.id)">
              {{ temporaryProduct.name }}
            </a>
          </li>
          <li class="nav-item" *ngFor="let product of userProducts; let index = index">
            <a
              class="nav-link"
              [routerLink]="['/product', product.name, product.id]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
              (click)="onProductSelect(product.id)">
              {{ product.name }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/search']" routerLinkActive="active" (click)="onSearchClick()">Search Product</a>
          </li>
        </ul>
        <button type="button" class="btn btn-secondary save-dpr-button">
          <span class="material-icons-outlined" aria-hidden="true">add_a_photo</span>
          Save this DPR
        </button>
      </div>
    </div>
    <img src="/assets/Logo_TotalEnergies.svg" alt="totalenergies_logo" />
  </div>
</nav>

<app-alert *ngIf="authenticationService.showAlert"></app-alert>
