import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ProductComponent } from "./product/product.component";
import { SearchProductComponent } from "./search-product/search-product.component";

const routes: Routes = [
  {
    path: "product/:name/:id",
    component: ProductComponent,
    canActivate: [MsalGuard],
  }, // Route to the products
  {
    path: "search",
    component: SearchProductComponent,
    canActivate: [MsalGuard],
  }, // Add Search Component
  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
    canActivate: [MsalGuard],
  }, // Redirect to the default product
  { path: "**", redirectTo: "", canActivate: [MsalGuard] }, // Manage route not founds
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
