import {Component, ViewChild} from "@angular/core";
import {MatMenu} from "@angular/material/menu";
import {MsalService} from "@azure/msal-angular";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent {
  @ViewChild("profileMenu", {static: true}) profileMenu: MatMenu;

  constructor(public authService: MsalService) {}

  logout(): void {
    this.authService.logout();
  }
}
