import { MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

export const msalInterceptorConfigFactory =
  (): MsalInterceptorConfiguration => {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
      "user.read",
    ]);
    protectedResourceMap.set(
      environment.webProtocol + "://" + environment.serverUrl,
      [environment.clientId + "/.default"]
    );
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    } as MsalInterceptorConfiguration;
  };
