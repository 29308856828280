<ng-select
  id="user-autocomplete"
  [ngClass]="multiple ? 'select-control-multiple' : 'select-control'"
  [clearable]="multiple"
  [items]="users$ | async"
  bindLabel="fullName"
  [multiple]="multiple"
  [minTermLength]="minLengthTerm"
  [loading]="usersLoading"
  typeToSearchText="Please enter {{ minLengthTerm }} or more characters"
  [typeahead]="usersInput$"
  [(ngModel)]="model"
  (change)="onUserSelected.emit(multiple ? selectedUsers : [selectedUser])"
  [placeholder]="placeholder">
  <ng-template ng-option-tmp let-item="item">
    <div>{{ item.fullName }}</div>
  </ng-template>
</ng-select>
