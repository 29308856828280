<popup-header [dialogRef]="dialogRef" icon="calendar_today" text="{{ title }}"></popup-header>
<div id="manage-action-dialog">
  <form [formGroup]="actionForm">
    <!-- Title -->
    <div class="form-group">
      <label for="actionTitle" class="form-label">Title</label>
      <input type="text" class="form-control" id="actionTitle" formControlName="title" required placeholder="Action Title" />
    </div>

    <!-- Type -->
    <div class="form-group">
      <label for="type" class="form-label">Type</label>
      <select class="form-select" id="type" formControlName="type">
        <option value="Action">Action</option>
        <option value="Decision">Decision</option>
        <option value="Info">Info</option>
      </select>
    </div>

    <!-- Status -->
    <div class="form-group">
      <label for="status" class="form-label">Status</label>
      <select class="form-select" id="status" formControlName="status">
        <option *ngFor="let option of statusOptions" [value]="option">{{ option }}</option>
      </select>
    </div>

    <!-- Committee -->
    <div class="form-group">
      <label for="committee" class="form-label">Committee</label>
      <div id="committee">
        <div class="form-check">
          <input type="radio" id="committeeDPR" class="form-check-input" formControlName="committee" value="DPR" />
          <label for="committeeDPR" class="form-check-label">DPR</label>
        </div>
        <div class="form-check">
          <input type="radio" id="committeeDPC" class="form-check-input" formControlName="committee" value="DPC" />
          <label for="committeeDPC" class="form-check-label">DPC</label>
        </div>
        <div class="form-check">
          <input type="radio" id="committeeNone" class="form-check-input" formControlName="committee" value="No committee" />
          <label for="committeeNone" class="form-check-label">No committee</label>
        </div>
      </div>
    </div>

    <!-- Creation Date -->
    <div class="form-group">
      <label for="creationDate" class="form-label">Creation Date</label>
      <input type="date" class="form-control" id="creationDate" formControlName="creationDate" />
    </div>

    <!-- Pillar -->
    <div class="form-group">
      <label for="pillarName" class="form-label">Pillar</label>
      <select class="form-select" id="pillar-name" formControlName="pillarName">
        <option *ngFor="let pillar of pillars" [value]="pillar.name">
          {{ pillar.name }}
        </option>
      </select>
    </div>

    <!-- Responsible -->
    <div class="form-group">
      <label for="responsible" class="form-label">Responsible</label>
      <app-user-autocomplete
        [placeholder]="'Select Responsible'"
        [multiple]="false"
        [initialSelectedUsers]="[actionForm.value.responsible]"
        (onUserSelected)="onUsersSelected($event, 'responsible')"></app-user-autocomplete>
    </div>

    <!-- Deadline -->
    <div class="form-group">
      <label for="deadline" class="form-label">Deadline</label>
      <input
        type="date"
        class="form-control"
        id="deadline"
        formControlName="deadline"
        [min]="actionForm.get('creationDate')?.value | date : 'yyyy-MM-dd':'UTC'"
        [disabled]="actionForm.get('type')?.value !== 'Action'" />
    </div>

    <!-- Comment with Autocomplete -->
    <div class="form-group comment-container">
      <label for="comment" class="form-label">Comment</label>
      <div
        id="comment"
        class="form-control"
        contenteditable="true"
        value="actionForm.get('comment')?.value"
        placeholder="Add a comment (optional)"
        (input)="onCommentInput($event)"
        aria-placeholder="Add a comment"></div>

      <!-- Liste of filtered countries -->
      <ul *ngIf="filteredCountries$.length > 0" class="country-list-tag-autocomplete">
        <li
          *ngFor="let country of filteredCountries$"
          (click)="selectCountry(country.name)"
          (keydown)="$event.key == 'Enter' ? selectCountry(country.name) : null"
          class="country-list-tag-element">
          <img [src]="country.flag" width="20" height="12" alt="{{ country.name }}" />{{ country.name }}
        </li>
      </ul>
    </div>
  </form>

  <!-- Action buttons -->
  <div class="form-actions">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="actionForm.invalid || saveInProgress">
      {{ isEditMode ? "Save Changes" : "Create Action" }}
    </button>
  </div>
</div>
