import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import countriesData from "../common/countries.json";
import {UserDTO} from "../common/user.service";
import {DateUtil} from "../common/util/date-util";
import {Product, ProductDetailsService} from "./product-details.service";
@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnChanges {
  productDetails: any = {};
  editForm: FormGroup = this.fb.group({});
  isEditMode: boolean = false;
  countries: Array<{code: string; name: string; flag: string}> = [];
  statusFields = [];
  viewFields = [];
  editableFields = [];

  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() selectedProductId!: number;
  @Input() section!: string;

  branches = ["EP", "GRP", "HD", "HUT", "MS", "OT", "RC", "TGS", "TS"]; // Predefined list of branches
  affiliates = [];
  domains = [
    "Enhanced Operations",
    "Supply Chain",
    "Production & Emissions",
    "Drilling & Wells",
    "Subsurface",
    "Project & Construction",
    "Availability & Integrity",
    "Supply Chain & Recycling",
    "HSE & Sustainability ",
    "Planning & Scheduling",
    "Process Optimization",
  ]; // Single-choice domain list
  squadProfiles = ["Mono Product", "Multi Product", "Hybride"]; // Squad profile options

  constructor(private productDetailsService: ProductDetailsService, private fb: FormBuilder, private toastrService: ToastrService) {
    this.countries = Object.entries(countriesData).map(([code, name]) => ({
      code,
      name,
      flag: `assets/flags/${code.toLowerCase()}.svg`,
    }));

    this.affiliates = this.countries.map((country) => country.name);

    // Initialize the reactive form
    this.editForm = this.fb.group({
      logo: "",
      name: "",
      branches: [],
      affiliates: [],
      domain: [""],
      sponsors: [[]],
      productOwners: [[]],
      domainLeads: [[]],
      itScaler: [[]],
      squadProfile: [""],
      statusCreationDate: new Date().toISOString().split("T")[0],
      statusTransferDate: new Date().toISOString().split("T")[0],
      statusRunDate: new Date().toISOString().split("T")[0],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProductId?.currentValue) {
      this.isEditMode = false;
      this.loadProductDetails();
    }
  }

  loadProductDetails(): void {
    this.productDetailsService.getProductDetails(this.selectedProductId).subscribe((data) => {
      this.productDetails = data;
      this.populateForm(data);
      this.statusFields = [
        {
          label: "Creation",
          formControlName: "statusCreationDate",
          value: this.productDetails?.statusCreationDate || null,
        },
        {
          label: "Transfer",
          formControlName: "statusTransferDate",
          value: this.productDetails?.statusTransferDate || null,
        },
        {
          label: "Run",
          formControlName: "statusRunDate",
          value: this.productDetails?.statusRunDate || null,
        },
      ];

      this.viewFields = [
        {
          label: "Branch",
          value: this.getFormattedBranches(),
        },
        {
          label: "Affiliate",
          value: this.getFormattedAffiliates(),
        },
        {
          label: "Domain",
          value: this.productDetails?.domain || "No Data",
        },
        {
          label: "Sponsor",
          value: this.getFormattedSponsors(),
        },
        {
          label: "Product Owner",
          value: this.getFormattedProductOwners(),
        },
        {
          label: "Domain Lead",
          value: this.getFormattedDomainLeads(),
        },
        {
          label: "IT Scaler",
          value: this.getFormattedITScaler(),
        },
        {
          label: "Squad Profile",
          value: this.productDetails?.squadProfile || "No Data",
        },
      ];

      this.editableFields = [
        {
          label: "Branch",
          type: "ng-select",
          items: this.branches,
          formControlName: "branches",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Affiliate",
          type: "ng-select",
          items: this.affiliates,
          formControlName: "affiliates",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Domain",
          type: "ng-select",
          items: this.domains,
          formControlName: "domain",
          multiple: false,
          required: true,
          class: "select-control",
        },
        {
          label: "Sponsor",
          type: "user-autocomplete",
          value: this.editForm.value.sponsors || [],
          formControlName: "sponsors",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Product Owner",
          type: "user-autocomplete",
          value: this.editForm.value.productOwners || [],
          formControlName: "productOwners",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Domain Lead",
          type: "user-autocomplete",
          value: this.editForm.value.domainLeads || [],
          formControlName: "domainLeads",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "IT Scaler",
          type: "user-autocomplete",
          value: this.editForm.value.itScaler ? [this.editForm.value.itScaler] : [],
          formControlName: "itScaler",
          multiple: false,
          required: false,
          class: "select-control",
        },
        {
          label: "Squad Profile",
          type: "ng-select",
          items: this.squadProfiles,
          formControlName: "squadProfile",
          multiple: false,
          required: true,
          class: "select-control",
        },
      ];
    });
  }

  populateForm(details: Product): void {
    this.editForm.patchValue({
      logo: details.logo,
      name: details.name,
      branches: details.branches || [],
      affiliates: details.affiliates || [],
      domain: details.domain || "",
      sponsors: details.sponsors || [],
      productOwners: details.productOwners || [],
      domainLeads: details.domainLeads || [],
      itScaler: details.itScaler || null,
      squadProfile: details.squadProfile || "",
      statusCreationDate: details.statusCreationDate ? DateUtil.dateWithoutTimezone(details.statusCreationDate) : null,
      statusTransferDate: details.statusTransferDate ? DateUtil.dateWithoutTimezone(details.statusTransferDate) : null,
      statusRunDate: details.statusRunDate ? DateUtil.dateWithoutTimezone(details.statusRunDate) : null,
    });
  }

  toggleEditMode(enable: boolean = true): void {
    this.isEditMode = enable;
    if (!enable) {
      this.populateForm(this.productDetails); // Reset changes if edit mode is canceled
    }
  }

  saveChanges(): void {
    const updatedProduct: Product = {
      id: this.selectedProductId,
      name: this.editForm.value.name,
      logo: this.editForm.value.logo,
      branches: this.editForm.value.branches,
      affiliates: this.editForm.value.affiliates,
      sponsors: this.editForm.value.sponsors,
      productOwners: this.editForm.value.productOwners,
      domainLeads: this.editForm.value.domainLeads,
      domain: this.editForm.value.domain || null,
      itScaler: this.editForm.value.itScaler[0] ? this.editForm.value.itScaler[0] || null : this.editForm.value.itScaler || null,
      squadProfile: this.editForm.value.squadProfile || null,
      statusCreationDate: this.editForm.value.statusCreationDate || null,
      statusTransferDate: this.editForm.value.statusTransferDate || null,
      statusRunDate: this.editForm.value.statusRunDate || null,
    };

    this.productDetailsService.updateProductDetails(this.selectedProductId, updatedProduct).subscribe(
      (response) => {
        this.toastrService.success("Product updated");
        this.loadProductDetails();
        this.isEditMode = false;
      },
      (error) => {
        this.toastrService.error("Failed to edit product");
      }
    );
  }

  /** FORMAT DATA METHODS **/
  getFormattedBranches(): string {
    return this.productDetails?.branches?.join(" & ") || "No Data";
  }

  getFormattedAffiliates(): string {
    return this.productDetails?.affiliates?.join(" & ") || "No Data";
  }

  getFormattedSponsors(): string {
    return this.productDetails?.sponsors?.map((sponsor) => sponsor.fullName).join(" & ") || "No Data";
  }

  getFormattedProductOwners(): string {
    return this.productDetails?.productOwners?.map((owner) => owner.fullName).join(" & ") || "No Data";
  }

  getFormattedDomainLeads(): string {
    return this.productDetails?.domainLeads?.map((domainLead) => domainLead.fullName).join(" & ") || "No Data";
  }

  getFormattedITScaler(): string {
    return this.productDetails?.itScaler?.fullName ? this.productDetails.itScaler.fullName : "No Data";
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    this.editForm.patchValue({
      [key]: selectedUsers.map((user) => ({
        ...user,
      })),
    });
  }
}
