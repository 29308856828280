<div id="search-product-page">
  <div class="filters">
    <!-- Filter: Product Name -->
    <div class="form-group">
      <label class="form-label" for="productNameFilter">Product Name</label>
      <ng-select
        [items]="productNameOptions"
        bindLabel="name"
        [(ngModel)]="productNameFilter"
        [searchable]="true"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Product Owner -->
    <div class="form-group">
      <label class="form-label" for="productOwnerFilter">Product Owner</label>
      <ng-select
        [items]="productOwnerOptions"
        bindLabel="productOwner"
        [(ngModel)]="productOwnerFilter"
        [searchable]="true"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Branch -->
    <div class="form-group">
      <label class="form-label" for="branchFilter">Branch</label>
      <ng-select
        [items]="branchOptions"
        bindLabel="branch"
        [(ngModel)]="branchFilter"
        [searchable]="false"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <div class="form-group">
      <label class="form-label" for="affiliateFilter">Affiliate</label>
      <ng-select
        [items]="affiliateOptions"
        bindLabel="affiliate"
        [(ngModel)]="affiliateFilter"
        [searchable]="true"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Domain -->
    <div class="form-group">
      <label class="form-label" for="domainFilter">Domain</label>
      <ng-select
        [items]="domainOptions"
        bindLabel="domain"
        [(ngModel)]="domainFilter"
        [searchable]="false"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Squad Profile -->
    <div class="form-group">
      <label class="form-label" for="squadFilter">Squad Profile</label>
      <ng-select
        [items]="squadOptions"
        bindLabel="squad"
        [(ngModel)]="squadFilter"
        [searchable]="false"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Reset Filters Button -->
    <button class="btn btn-link" (click)="resetFilters()" matTooltip="Reset the filters">
      <span class="material-icons" style="color: red">filter_list_off</span>
    </button>
  </div>

  <div id="search-result">
    <div id="search-result-header">
      <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
        {{ "search" }}
      </span>
      <h4>{{ "Search Result" }}</h4>
    </div>
    <table class="table" id="product-table">
      <caption>
        Products
      </caption>
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">Product Name</th>
          <th scope="col">Product Owner</th>
          <th scope="col">Branch</th>
          <th scope="col">Affiliate</th>
          <th scope="col">Domaine</th>
          <th scope="col">Squad Profile</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of paginatedProducts">
          <td>
            <span
              class="material-icons-outlined icon favorites"
              title="Favorites"
              (click)="toggleFavorite(product.id, product.favorite)"
              (keydown)="$event.key == 'Enter' ? toggleFavorite(product.id, product.favorite) : null">
              {{ product.favorite ? "star" : "star_outline" }}
            </span>
          </td>
          <td>
            <span
              class="material-icons-outlined icon redirection"
              title="See Product"
              (click)="redirectToProduct(product)"
              (keydown)="$event.key == 'Enter' ? redirectToProduct(product) : null">
              visibility
            </span>
          </td>
          <td>
            <div class="product-logo-name-section">
              <img [src]="product.logo ? product.logo : '../../assets/logo_deploy_cockpit.svg'" alt="{{ product.name }}" class="product-logo-img" />
              <span>{{ product.name }}</span>
            </div>
          </td>
          <td>
            {{ getFormattedProductOwners(product) }}
          </td>
          <td>
            {{ product.branches.join(" & ") }}
          </td>
          <td>
            {{ product.affiliates.join(" & ") }}
          </td>
          <td>{{ product.domain }}</td>
          <td>{{ product.squadProfile }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination Section -->
    <div class="d-flex align-items-center mt-3">
      <div class="align-middle">{{ lowerLimit }} - {{ upperLimit }} of {{ filteredProducts.length }}</div>

      <ul class="pagination ms-2">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <button class="page-link" aria-label="Previous" (click)="changePage(currentPage - 1)">
            <span class="material-icons-outlined" aria-hidden="true">chevron_left</span>
          </button>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <button class="page-link" aria-label="Next" (click)="changePage(currentPage + 1)">
            <span class="material-icons-outlined" aria-hidden="true">chevron_right</span>
          </button>
        </li>
      </ul>
      <div class="ms-auto">
        <label for="itemsPerPage" class="form-label me-2">Items per page:</label>
        <select id="itemsPerPage" class="form-select" [(ngModel)]="itemsPerPage" (change)="updatePagination()">
          <option *ngFor="let option of [5, 10, 20, 50]" [value]="option">{{ option }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
