import {Component, Inject, OnDestroy} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import moment from "moment";
import {ToastrService} from "ngx-toastr";
import countriesData from "../common/countries.json";
import {RoadmapGroup, RoadmapGroupHistory, RoadmapService} from "../common/roadmap.service";

@Component({
  selector: "app-add-group",
  templateUrl: "./add-group.component.html",
  styleUrls: ["./add-group.component.scss"],
})
export class AddGroupComponent implements OnDestroy {
  addGroupForm: FormGroup;
  countries: Array<{code: string; name: string; flag: string}> = [];
  title: string;
  isEditMode: boolean;
  minEventStartDate: Date;
  maxEventEndDate: Date;
  dateError: string | null = null;
  groupHistory: RoadmapGroupHistory[] = [];
  saveInProgress: boolean = false;

  intervalCheckRange: any;

  constructor(
    private fb: FormBuilder,
    private roadmapService: RoadmapService,
    public dialogRef: MatDialogRef<AddGroupComponent>,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {productId: number; group?: RoadmapGroup}
  ) {
    // Check if edit mode
    this.isEditMode = !!this.data.group;
    this.title = this.isEditMode ? "Edit Group of Event" : "Create Group of Event";

    // Transform json data to include flags
    this.countries = Object.entries(countriesData).map(([code, name]) => ({
      code,
      name,
      flag: `assets/flags/${code.toLowerCase()}.svg`,
    }));

    // Initialise form
    this.addGroupForm = this.fb.group(
      {
        product: [{id: this.data.productId}],
        name: ["", Validators.required],
        type: ["Deploy"],
        country: ["France"],
        startDate: [new Date().toISOString()],
        endDate: [new Date().toISOString()],
        status: ["Ready for site"],
        events: this.fb.array([]),
        comment: [""],
        creationDate: [new Date().toISOString()],
      },
      {validators: this.dateRangeValidator.bind(this)}
    );

    // If edit mode, fill with existing values
    if (this.isEditMode && this.data.group) {
      this.addGroupForm.patchValue({
        name: this.data.group.name,
        type: this.data.group.type,
        country: this.data.group.country,
        startDate: moment(this.data.group.startDate).toISOString(),
        endDate: moment(this.data.group.endDate).toISOString(),
        status: this.data.group.status,
        comment: this.data.group.comment,
        creationDate: moment(this.data.group.creationDate).toISOString(),
      });

      this.setEventDateConstraints();
      // Fetch history data for the group
      this.fetchGroupHistory(this.data.group.id);

      /** Fixing weird bug where the date range is considered invalid when the start date is modified before the end date **/
      this.intervalCheckRange = setInterval(() => {
        this.addGroupForm.controls["startDate"].updateValueAndValidity();
        this.addGroupForm.controls["endDate"].updateValueAndValidity();
      }, 1000);
    }
  }

  private fetchGroupHistory(groupId: number): void {
    this.roadmapService.getGroupHistory(groupId).subscribe((groupHistory) => {
      this.groupHistory = groupHistory;
    });
  }

  private setEventDateConstraints(): void {
    const eventDates = this.data.group?.events.map((event) => ({
      start: new Date(event.startDate),
      end: new Date(event.endDate),
    }));

    if (eventDates && eventDates.length > 0) {
      this.minEventStartDate = new Date(Math.min(...eventDates.map((d) => d.start.getTime())));
      this.maxEventEndDate = new Date(Math.max(...eventDates.map((d) => d.end.getTime())));
    }
  }

  private dateRangeValidator(control: AbstractControl): {[key: string]: boolean} | null {
    const startDate = new Date(control.get("startDate")?.value);
    const endDate = new Date(control.get("endDate")?.value);

    if (this.minEventStartDate && this.maxEventEndDate && (startDate > this.minEventStartDate || endDate < this.maxEventEndDate)) {
      this.dateError = `The selected date range should be less than ${this.minEventStartDate.toLocaleDateString()} and greater than ${this.maxEventEndDate.toLocaleDateString()}.`;
      return {dateOutOfRange: true};
    }
    this.dateError = null;
    return null;
  }

  onSubmit(): void {
    if (this.addGroupForm.valid) {
      const formData = this.addGroupForm.value;
      this.saveInProgress = false;
      this.roadmapService.saveGroup(formData, this.data.group?.id).subscribe((res) => {
        this.dialogRef.close(true);
        if (res) {
          this.saveInProgress = false;
          this.toastrService.success("Group has been saved !");
        } else {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the group.");
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    clearInterval(this.intervalCheckRange);
  }
}
