import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

export interface ProductDTO {
  id: number;
  name: string;
  logo: string;
}

export interface UserDTO {
  email: string;
  fullName: string;
}

export interface ProductWithFavorite {
  id: number;
  name: string;
  logo: string;
  productOwners: UserDTO[];
  branches: string[];
  affiliates: string[];
  sponsors: UserDTO[];
  domainLeads: UserDTO[];
  domain: string | null;
  itScaler: UserDTO;
  squadProfile: string | null;
  statusCreationDate: Date | null;
  statusTransferDate: Date | null;
  statusRunDate: Date | null;
  favorite: boolean;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  private apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/users`; // URL user
  private productApiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/products`; // URL products
  private isAdmin_bool: boolean;

  constructor(private http: HttpClient) {}

  setUserIsAdmin(value: boolean): void {
    this.isAdmin_bool = value;
  }

  getUserIsAdmin(): boolean | undefined {
    return this.isAdmin_bool;
  }

  //Check if user is admin
  isAdmin(email: string): Observable<boolean> {
    return this.http.get<boolean>(this.apiUrl + "/isAdmin", {
      params: {email},
    });
  }

  // Check if the user has edit rights
  editRightsBySection(email: string, productId: number): Observable<string[]> {
    const params = {email, productId: productId.toString()};
    return this.http.get<string[]>(this.apiUrl + "/rights-by-section", {
      params,
    });
  }

  // Get products where user has edit rights
  getProductsForUserWithEditRights(email: string): Observable<ProductDTO[]> {
    return this.http.get<ProductDTO[]>(`${this.productApiUrl}/user-with-rights`, {
      params: {email},
    });
  }

  getProducts(): Observable<ProductWithFavorite[]> {
    return this.http.get<ProductWithFavorite[]>(`${this.productApiUrl}/with-favorites`);
  }

  addFavorite(productId: number): Observable<string> {
    const params = new HttpParams().set("productId", productId.toString());
    return this.http.post<string>(`${this.productApiUrl}/add-favorite`, null, {params});
  }

  removeFavorite(productId: number): Observable<string> {
    const params = new HttpParams().set("productId", productId.toString());
    return this.http.get<string>(`${this.productApiUrl}/remove-favorite`, {params});
  }

  searchUsers(query: string): Observable<UserDTO[]> {
    return this.http.get<UserDTO[]>(`${this.apiUrl}/search`, {
      params: {query},
    });
  }
}
