import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsageService {
  private baseUrl: string = `${environment.webProtocol}://${environment.serverUrl}/api/usage/users-count`;

  constructor(private http: HttpClient) {}

  getUsersCount(productId: number, duration: string): Observable<any> {
    let params = new HttpParams().set("productId", productId).set("duration", duration);
    return this.http.get<any>(this.baseUrl, {params: params});
  }
}
