import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {UserDTO} from "src/app/common/user.service";
import {DateUtil} from "src/app/common/util/date-util";
import {EightPillarsService, Pillar} from "src/app/eight-pillars/eight-pillars.service";
import countriesData from "../../common/countries.json";
import {ActionPlan, ActionPlanService} from "../action-plan.service";
@Component({
  selector: "app-manage-action",
  templateUrl: "./manage-action.component.html",
  styleUrls: ["./manage-action.component.scss"],
})
export class ManageActionComponent implements OnInit {
  actionForm: FormGroup;
  title: string;
  isEditMode: boolean;
  pillars: Pillar[] = [];
  countries: Array<{code: string; name: string; flag: string}> = [];
  filteredCountries$: Array<{code: string; name: string; flag: string}> = [];
  taggingCountry: boolean = false;
  statusOptions: string[] = ["To do", "In progress", "Blocked", "Done", "Non Applicable"];
  activeMentionIndex: number = 0;
  saveInProgress: boolean = false;

  constructor(
    readonly fb: FormBuilder,
    readonly actionPlanService: ActionPlanService,
    public dialogRef: MatDialogRef<ManageActionComponent>,
    readonly toastrService: ToastrService,
    readonly pillarService: EightPillarsService,
    @Inject(MAT_DIALOG_DATA) public data: {productId: number; action?: ActionPlan}
  ) {
    this.countries = Object.entries(countriesData).map(([code, name]) => ({
      code,
      name,
      flag: `assets/flags/${code.toLowerCase()}.svg`,
    }));

    this.isEditMode = !!this.data.action;
    this.title = this.isEditMode ? "Edit Action" : "Create New Action";
  }

  updateStatusOptions(type: string) {
    const statusControl = this.actionForm.get("status");
    const deadlineControl = this.actionForm.get("deadline");
    if (type === "Info") {
      // Set status to 'Non Applicable' when type is 'Info'
      this.statusOptions = ["Non Applicable"];
      statusControl?.setValue("Non Applicable");
      statusControl?.disable(); // Disable the status field
    } else {
      // Enable status and update available options when type is not 'Info'
      statusControl?.enable();
      this.statusOptions = ["To do", "In progress", "Blocked", "Done"]; // Valid options
      if (statusControl?.value === "Non Applicable") {
        statusControl?.setValue("To do"); // Reset to a valid status if it's 'Non Applicable'
      }
    }

    if (type === "Action") {
      deadlineControl?.enable();
    } else {
      deadlineControl?.disable();
    }
  }

  ngOnInit(): void {
    const isInfoType = this.isEditMode && this.data.action?.type === "Info";
    const isActionType = this.isEditMode && this.data.action?.type === "Action";

    this.actionForm = this.fb.group({
      title: ["", Validators.required],
      type: ["Action", Validators.required],
      status: [{value: isInfoType ? "Non Applicable" : "To do", disabled: isInfoType}, Validators.required],
      pillarCard: [{id: null}, Validators.required],
      pillarName: ["", Validators.required],
      creationDate: [{value: new Date().toISOString().split("T")[0], disabled: this.isEditMode}, Validators.required],
      responsible: ["", Validators.required],
      deadline: [{value: "", disabled: isActionType}],
      comment: [""],
      committee: ["No committee"],
    });
    if (this.data.productId) {
      this.pillarService.getPillarsByProductId(this.data.productId).subscribe((data) => {
        this.pillars = data;
        if (this.pillars.length > 0) {
          this.actionForm.patchValue({
            pillarCard: {id: this.pillars[0].id},
            pillarName: this.pillars[0].name,
          });

          if (this.isEditMode && this.data.action) {
            let pillar = this.pillars.find((pillar) => pillar.name === this.data.action.pillarName);
            let pillarId = pillar.id;
            this.actionForm.patchValue({
              title: this.data.action.title,
              type: this.data.action.type,
              status: this.data.action.status,
              pillarCard: {id: pillarId},
              pillarName: this.data.action.pillarName,
              creationDate: DateUtil.dateWithoutTimezone(this.data.action.creationDate),
              responsible: this.data.action.responsible[0],
              deadline: DateUtil.dateWithoutTimezone(this.data.action.deadline),
              comment: this.data.action.comment,
              committee: this.data.action.committee,
            });
            const commentElement = document.getElementById("comment") as HTMLDivElement;
            if (commentElement) {
              commentElement.innerHTML = this.checkForCountryAndWrapWithBlueSpan(this.data.action.comment);
            }
          }
        }
      });
    }
    this.actionForm.get("pillarName")?.valueChanges.subscribe((newPillarName: string) => {
      const selectedPillar = this.pillars.find((pillar) => pillar.name === newPillarName);
      if (selectedPillar) {
        this.actionForm.patchValue({pillarCard: {id: selectedPillar.id}}, {emitEvent: false});
      }
    });
    // Listen to changes in the 'type' field
    this.actionForm.get("type")?.valueChanges.subscribe((type: string) => {
      this.updateStatusOptions(type);
    });

    const initialType = this.actionForm.get("type")?.value;
    this.updateStatusOptions(initialType);
  }

  onCommentInput(event: InputEvent): void {
    const commentElement = event.target as HTMLDivElement;
    const input = commentElement.innerText.trim();

    // Update the form
    this.actionForm.get("comment")?.setValue(input);

    // Match all @ mentions
    const atMatches = [...input.matchAll(/@([\p{L}\p{N}_]*)/gu)]; // Use Unicode regex

    if (event.data === "@") {
      // When "@" is typed, store its position
      this.taggingCountry = true;
      this.activeMentionIndex = atMatches.findIndex((match) => match[0] === "@");
    }

    // If tagging is active and we have matches
    if (atMatches.length && this.taggingCountry && this.activeMentionIndex !== undefined) {
      const mentionMatch = atMatches[this.activeMentionIndex]; // Get the match at the recorded index
      const mentionText = mentionMatch ? mentionMatch[1] : ""; // Extract the text after "@"

      // Filter countries based on the mention text
      this.filteredCountries$ = this.countries.filter((country) => country.name.toLowerCase().startsWith(mentionText.toLowerCase()));
    } else {
      // Reset the filtered list if no match
      this.filteredCountries$ = [];
    }
  }

  checkForCountryAndWrapWithBlueSpan(text: string) {
    const countryMentions = [...text.matchAll(/@([\p{L}\p{N}_]*)/gu)];

    let updatedText = text;
    countryMentions.forEach((match) => {
      const countryName = match[1];
      // If the country exists in the list, wrap it in a blue <span>
      if (this.countries.some((c) => c.name.toLowerCase().replace(/\s+/g, "") === countryName.toLowerCase())) {
        updatedText = updatedText.replace(match[0], `<span style="color: blue">${match[0]}</span>`);
      }
    });

    return updatedText;
  }

  selectCountry(country: string): void {
    const commentElement = document.getElementById("comment") as HTMLDivElement;
    const input = commentElement.innerText;

    // Search for @ mentions in the text
    const atMatches = [...input.matchAll(/@([\p{L}\p{N}_]*)/gu)];
    if (atMatches.length) {
      const lastMatch = atMatches[this.activeMentionIndex];
      const beforeMention = input.slice(0, lastMatch.index);
      const afterMention = input.slice(lastMatch.index + lastMatch[0].length);

      // Apply formatting to the text before and after the mention
      const formattedBefore = this.checkForCountryAndWrapWithBlueSpan(beforeMention);
      const formattedAfter = this.checkForCountryAndWrapWithBlueSpan(afterMention);

      // Format the new country mention
      const countryMention = `@${country.replace(/\s+/g, "")}`;
      const formattedNewMention = `<span style="color: blue">${countryMention}</span>&ensp;`;

      // Construct the updated HTML content with the formatted mention
      const updatedContent = `${formattedBefore}${formattedNewMention}${formattedAfter}`;

      // Update the comment's HTML content
      commentElement.innerHTML = updatedContent;

      // Place the cursor right after the new mention
      const range = document.createRange();
      const selection = window.getSelection();

      const newMentionSpan = Array.from(commentElement.querySelectorAll("span")).find(
        (span) => span.textContent === `@${country.replace(/\s+/g, "")}`
      );

      if (newMentionSpan) {
        range.setStart(newMentionSpan.nextSibling, 1); // Set cursor right after the new mention
        range.collapse(true); // Collapse to ensure it's a blinking cursor
      }

      selection?.removeAllRanges();
      selection?.addRange(range);

      this.taggingCountry = false;
    }

    // Reset the country suggestions
    this.filteredCountries$ = [];
  }

  onSubmit(): void {
    const commentElement = document.getElementById("comment") as HTMLDivElement;
    const commentText = commentElement.textContent || commentElement.innerText;
    this.actionForm.patchValue({
      comment: commentText,
    });

    if (this.actionForm.valid) {
      const rawFormData = this.actionForm.getRawValue();
      const formData = {
        ...rawFormData,
        pillarCard: {id: rawFormData.pillarCard.id},
      };
      this.saveInProgress = true;

      if (this.isEditMode) {
        this.actionPlanService.updateAction(this.data.action.id, formData).subscribe(
          () => {
            this.saveInProgress = false;
            this.toastrService.success("Action has been updated!");
            this.dialogRef.close(true);
          },
          () => {
            this.saveInProgress = false;
            this.toastrService.error("Failed to update action.");
          }
        );
      } else {
        this.actionPlanService.createAction(formData).subscribe(
          () => {
            this.saveInProgress = false;
            this.toastrService.success("Action has been created!");
            this.dialogRef.close(true);
          },
          () => {
            this.saveInProgress = false;
            this.toastrService.error("Failed to create action.");
          }
        );
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    this.actionForm.patchValue({
      [key]: selectedUsers[0],
    });
  }
}
