import {Component, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatMenuTrigger} from "@angular/material/menu";
import {saveAs} from "file-saver";
import * as moment from "moment";
import {ToastrService} from "ngx-toastr";
import {DataSet} from "vis-data";
import {Timeline} from "vis-timeline/esnext";
import {AddEventComponent} from "../add-event/add-event.component";
import {AddGroupComponent} from "../add-group/add-group.component";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {RoadmapEvent, RoadmapGroup, RoadmapRes, RoadmapService} from "../common/roadmap.service";

@Component({
  selector: "app-roadmap",
  templateUrl: "./roadmap.component.html",
  styleUrls: ["./roadmap.component.scss"],
})
export class RoadmapComponent implements OnChanges, OnDestroy {
  @Input() productId: number;
  private timeline: Timeline;
  private groupToEventMap: Map<string, string[]> = new Map();
  private groups: RoadmapGroup[];
  public currentEvent;
  public currentGroup;
  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() section: string;
  lastUpdate: Date;
  @ViewChild("menuEventTrigger", {static: false})
  menuEventTrigger: MatMenuTrigger;
  @ViewChild("menuGroupTrigger", {static: false})
  menuGroupTrigger: MatMenuTrigger;
  searchQuery: string = "";
  @ViewChild("searchContainer", {static: false}) searchContainer: ElementRef;
  searchResults: any[] = [];
  isSearchVisible: boolean = false;
  allEvents: RoadmapEvent[] = [];
  private focusedItemId: string | null = null;
  // Structure to track the groups
  private linesNumber: {
    dates: { start: string; end: string }[]
  }[] = [];

  private outsideClickListener = () => {
  };

  jiraLabels: Array<any> = [{
    name: "Func_evo",
    displayName: "Functional Evolution"
  }, {
    name: "Func_adapt",
    displayName: "Functional Adaptation"
  }, {
    name: "Deploy",
    displayName: "Deploy"
  }, {
    name: "Technical",
    displayName: "Technical"
  }, {
    name: "Cyber",
    displayName: "Cyber"
  }, {
    name: "Run",
    displayName: "Run"
  }];

  groupsLines: Array<Array<RoadmapGroup>> = [];

  // Dependency on the service
  constructor(private roadmapService: RoadmapService, private toastrService: ToastrService, private dialog: MatDialog, private renderer: Renderer2) {

  }

  get hasEvents(): boolean {
    return this.searchResults.some((result) => !result.events);
  }

  get hasGroups(): boolean {
    return this.searchResults.some((result) => result.events);
  }

  /*----INIT ROADMAP----*/

  // Check changes of input
  ngOnChanges(changes: SimpleChanges) {
    if (changes.productId && changes.productId.currentValue) {
      this.refreshRoadmap();
    }
  }

  refreshRoadmap() {
    // Init again groups and events
    this.groupToEventMap.clear();

    // Reset lines
    this.linesNumber = [];
    this.timeline?.destroy();

    // Reload datas
    this.loadRoadmapData();
  }

  // Load roadmap groups and their associated events
  private loadRoadmapData() {
    this.roadmapService.getRoadmapGroupsByProductId(this.productId).subscribe(
      (res: RoadmapRes) => {
        this.lastUpdate = res.roadmapLastUpdateDate;
        this.allEvents = res.roadmapGroups.flatMap((group) => group.events);
        this.groupsLines = this.placeGroupsOnLines(res.roadmapGroups);

        const flattenedGroups: { startDate: string; endDate: string }[] = [];
        for (const line of this.groupsLines) {
          flattenedGroups.push(...line);
        }
        this.groups = <Array<RoadmapGroup>>flattenedGroups;

        this.setIdsForJiraGroupsAndEvents();
        this.initializeGroupToEventMap();
        this.createTimelineWithBackendData();
      },
      (error) => console.error("Error while fetching groups", error)
    );
  }

  private setIdsForJiraGroupsAndEvents() {
    let nextJiraGroupId = 1000000;
    let nextJiraEventId = 2000000;

    this.groups.forEach((group) => {
      if (group.fromJira) {
        group.id = nextJiraGroupId++;

        group.events.forEach((event) => {
          event.id = nextJiraEventId++;
        });
      }
    });
  }

  // Create the timeline with data from the backend
  private createTimelineWithBackendData() {
    // Define timeline layout groups for different types
    this.linesNumber[1] = null; // Quarters
    this.linesNumber[2] = null; // Months

    const eventItems = this.generateEventItems();

    const startDate = moment(Math.min(...eventItems.map((item) => item.start)));
    const endDate = moment(Math.max(...eventItems.map((item) => item.end)));

    // Combine all items, ensuring unique IDs for quarters and months
    const items = new DataSet([
      ...this.generateQuarterItems(moment(startDate).subtract(2, "year"), moment(endDate).add(2, "year")),
      ...this.generateMonthItems(moment(startDate).subtract(2, "year"), moment(endDate).add(2, "year")),
      ...eventItems, // Use eventItems for both groups and events
    ]);

    const options: any = {
      stack: false,
      horizontalScroll: true,
      zoomable: false,
      start: moment().subtract(5, "months").toDate(),
      end: moment().add(5, "months").toDate(),
      tooltip: {followMouse: true},
      showCurrentTime: true,
      groupOrder: (a, b) => a.value - b.value,
      showMajorLabels: false,
      showMinorLabels: false,
      xss: {disabled: true},
      loadingScreenTemplate: function () {
        return "Loading the roadmap...";
      },
      margin: {
        item: {
          horizontal: 0
        }
      },
      groupHeightMode: 'fixed'
    };

    const container = document.getElementById("vis-timeline");
    this.timeline = new Timeline(container, items,
      new DataSet(Object.keys(this.linesNumber).map(lineNumber => {
        return {id: lineNumber, content: "", value: lineNumber}
      })), options);

    // Add event listeners for itemover, click, etc.
    this.setupTimelineEventHandlers(items);
  }

  /*----END INIT ROADMAP----*/

  /*----GENERATE ROADMAP ITEMS----*/

  // Generate items for quarters, ensuring unique IDs
  private generateQuarterItems(startDate: moment.Moment, endDate: moment.Moment) {
    const quarterItems = [];
    const currentDate = startDate.clone();
    let id = 0; // Ensure unique quarter IDs

    while (currentDate.isBefore(endDate)) {
      const year = currentDate.year();
      for (let quarter = 1; quarter <= 4; quarter++) {
        const startOfQuarter = moment(`${year}-01-01`).quarter(quarter).startOf("quarter");
        const endOfQuarter = startOfQuarter.clone().endOf("quarter");

        if (endOfQuarter.isAfter(endDate)) break;

        quarterItems.push({
          id: `quarter-${id++}`,
          content: `<p>Q${quarter} - ${year}</p><img class="quarter-flag flag" src="../assets/outlined_flag.svg">`,
          start: startOfQuarter.add(12, "hours").valueOf(),
          end: endOfQuarter.subtract(12, "hours").valueOf(),
          group: 1,
          className: "quarter",
        });
      }
      currentDate.add(1, "year");
    }
    return quarterItems;
  }

  // Generate items for months, ensuring unique IDs
  private generateMonthItems(startDate: moment.Moment, endDate: moment.Moment) {
    const monthItems = [];
    const currentDate = startDate.clone();
    let id = 0; // Ensure unique month IDs

    while (currentDate.isBefore(endDate)) {
      const startOfMonth = currentDate.clone().startOf("month");
      const endOfMonth = currentDate.clone().endOf("month");

      monthItems.push({
        id: `month-${id++}`,
        content: currentDate.locale("en").format("MMM"),
        start: startOfMonth.add(12, "hours").toISOString(),
        end: endOfMonth.subtract(12, "hours").toISOString(),
        group: 2,
        className: "month",
      });

      currentDate.add(1, "month");
    }

    return monthItems;
  }

  // Generate the event items
  private generateEventItems() {
    const eventItems = [];
    let nextGroupLineNumber = 3;

    this.groupsLines.forEach((line) => {
      let currentGroupLineNumber = nextGroupLineNumber++;

      line.forEach((group) => {
        let groupContent = `<div id="group-${group.id}" class="left-group-elements"><img src="../assets/expand_more.svg" class="up-arrow"><img src="../assets/vertical_line.svg">`;
        if (!group.fromJira) {
          groupContent += `<p>${group.name}</p></div>`;
          groupContent += `<img class="flag" id="${group.id}" src="../assets/outlined_flag.svg">`;
        } else {
          groupContent += `<p>${this.jiraLabels.find(label => label.name == group.name).displayName}</p></div>`;
        }

        // add group to elements
        eventItems.push({
          id: `group-${group.id}`, // unique id
          content: groupContent,
          start: group.startDate,
          end: group.endDate,
          group: currentGroupLineNumber,
          className: "group expand-less " + (group.fromJira ? "not-editable" : "") + " " + group.name.toLowerCase().replaceAll(" ", "-")
        });

        this.linesNumber[currentGroupLineNumber] = {
          dates: [{start: group.startDate, end: group.endDate}]
        };

        // Add events in group
        group.events.forEach((event) => {
          // Place event below group
          const eventLineNumber = this.findAvailableLine(event.startDate, event.endDate, currentGroupLineNumber + 1);

          eventItems.push({
            id: `event-${event.id}`, // unique id
            content: `<p id="event-${event.id}">${event.name}</p>`,
            start: event.startDate,
            end: event.endDate,
            group: eventLineNumber,
            text: event.name,
            className: "event " + (group.fromJira ? "not-editable" : "") + " " + group.name.toLowerCase().replaceAll(" ", "-"),
          });

          nextGroupLineNumber = Math.max(nextGroupLineNumber, eventLineNumber + 1);
        });
      });
    });

    return eventItems;
  }

  /*----END GENERATE ITEMS ROADMAP----*/

  /*----UTILS ROADMAP----*/
  private placeGroupsOnLines(groups: RoadmapGroup[]): any[][] {
    let groupsLines: any[][] = [];

    let jiraMinGroupLine = -1;
    groups.forEach((group) => {
      let placed = false;

      let startToLook = 0;
      if (group.fromJira) {
        if (jiraMinGroupLine == -1) jiraMinGroupLine = groupsLines.length;
        startToLook = jiraMinGroupLine;
      }

      for (let i = startToLook; i < groupsLines.length; i++) {
        const lineOfGroup = groupsLines[i];

        const overlap = lineOfGroup.some((sortedGroup) => this.groupsOverlap(group, sortedGroup));

        // If no overlap add the group
        if (!overlap) {
          lineOfGroup.push(group);
          placed = true;
          break;
        }
      }

      // Create new line if group not placed
      if (!placed) {
        groupsLines.push([group]);
      }
    });

    return groupsLines;
  }

  private groupsOverlap(groupA: { startDate: string; endDate: string }, groupB: { startDate: string; endDate: string }): boolean {
    // Parse the start and end dates of each group
    const startA = new Date(groupA.startDate);
    const endA = new Date(groupA.endDate);
    const startB = new Date(groupB.startDate);
    const endB = new Date(groupB.endDate);

    // Check for overlap: if the start of one group is within the range of the other, they overlap
    return startA <= endB && endA >= startB;
  }

  // Initialize the mapping between groups and their events
  private initializeGroupToEventMap() {
    this.groups.forEach((group) => {
      const associatedEvents = group.events.map((event) => `event-${event.id}`); // Ensure unique event IDs in the map
      this.groupToEventMap.set(`group-${group.id}`, associatedEvents);
    });
  }

  private findAvailableLine(
    start: string,
    end: string,
    minLineNumber: number = 3 // Start with 3 (first line for quarters, 2nd for months)
  ): number {
    const newStartDate = moment(start);
    const newEndDate = moment(end);

    // check dates overlaps
    const dateOverlaps = (existingDates: { start: string; end: string }[]): boolean => {
      return existingDates.some((dateRange) => {
        const existingStart = moment(dateRange.start);
        const existingEnd = moment(dateRange.end);
        return newStartDate.isBefore(existingEnd) && newEndDate.isAfter(existingStart);
      });
    };

    for (let i = minLineNumber; i < this.linesNumber.length; i++) {
      const line = this.linesNumber[i];

      // For events, check if it's under group
      if (!dateOverlaps(line.dates)) {
        line.dates.push({start, end});
        return i;
      }
    }

    // If no line available, add a new one
    const newLineNumber = Object.keys(this.linesNumber).length + 1;
    this.linesNumber[newLineNumber] = {
      dates: [{start, end}]
    };

    return newLineNumber;
  }

  /*----END UTILS ROADMAP----*/

  /*----HANDLE EVENTS ROADMAP----*/

  // Setup event listeners for the timeline
  private setupTimelineEventHandlers(items: DataSet<any>) {
    this.timeline.on("changed", () => {
      // select all elements with 'flag' in it
      const flags = document.querySelectorAll(".flag");

      flags.forEach((flag) => {
        flag.addEventListener("mouseenter", (event) => {
          this.handleFlagHover(event.target as HTMLElement);
        });

        flag.addEventListener("mouseleave", () => {
          this.hideTooltip();
        });
      });
    });

    this.timeline.on("click", (properties) => {
      if (properties.item) {
        const clickedItem: any = items.get(properties.item);
        const isArrowClicked = properties.event.srcElement?.classList.contains("up-arrow");

        if (clickedItem.className.includes("group")) {
          if (isArrowClicked) {
            // On click arrow, hide events
            this.toggleItemClass(clickedItem, items);
          } else {
            if (!clickedItem.className.includes("not-editable") && (this.isWriter || this.isAdmin)) {
              // Onclick other edit/delete group
              this.openMenuGroup(clickedItem, properties.event);
            }
          }
        } else {
          if (!clickedItem.className.includes("not-editable") && (this.isWriter || this.isAdmin)) {
            this.openMenuEvent(clickedItem, properties.event);
          }
        }
      }
    });

    this.timeline.on("itemover", (properties) => {
      if (properties.item) {
        const hoveredItem = items.get(properties.item);
        this.handleItemHover(hoveredItem);
      }
    });

    this.timeline.on("itemout", (properties) => {
      this.hideTooltip();
    });
  }

  // Handle hover events on items
  private handleItemHover(item) {
    if (item.className.includes("event")) {
      if (document.getElementById("tooltip")) {
        this.hideTooltip();
      }
      this.showTooltip(
        `${item.text}: ${moment(item.start).locale("en").format("DD/MM/YYYY")} - ${moment(item.end).locale("en").format("DD/MM/YYYY")}`,
        item
      );
    }
  }

  // Handle hover events on items
  private handleFlagHover(flagElement: HTMLElement) {
    const flagId = flagElement.id;

    if (flagElement.classList && flagElement.classList.contains("quarter-flag")) {
      const quarterElement = flagElement.parentElement.innerHTML;
      const match = quarterElement.match(/Q(\d) - (\d{4})/);

      if (match) {
        if (document.getElementById("tooltip")) {
          this.hideTooltip();
        }
        this.showTooltip(`Countries to deploy`, flagElement);
      }
    } else {
      // Display status for groups
      const groupId = parseInt(flagId);
      const group = this.groups.find((g) => g.id === groupId);

      if (group) {
        if (document.getElementById("tooltip")) {
          this.hideTooltip();
        }
        this.showTooltip(`Group Status: ${group.status}`, flagElement);
      }
    }
  }

  // Toggle the display of associated sub-items for a group
  private toggleItemClass(clickedItem, items) {
    if (clickedItem.className.includes("group")) {
      const associatedEvents = this.groupToEventMap.get(clickedItem.id);

      const newClass = clickedItem.className.includes("expand-less")
        ? clickedItem.className.replace("expand-less", "expand-more")
        : clickedItem.className.replace("expand-more", "expand-less");

      items.update({id: clickedItem.id, className: newClass});

      if (associatedEvents) {
        associatedEvents.forEach((eventId) => {
          const eventItem = items.get(eventId);
          const newEventClass = eventItem.className.includes("hidden")
            ? eventItem.className.replace("hidden", "").trim()
            : eventItem.className + " hidden";

          items.update({id: eventId, className: newEventClass});
        });
      }
    }
  }

  // Show the tooltip
  private showTooltip(content: string, item: any) {
    const tooltip = document.createElement("div");
    tooltip.id = "tooltip";
    tooltip.innerHTML = content;
    tooltip.style.position = "absolute";
    tooltip.style.backgroundColor = "white";
    tooltip.style.fontFamily = "Roboto, sans-serif";
    tooltip.style.fontSize = "10px";
    tooltip.style.fontWeight = "700";
    tooltip.style.color = "black";
    tooltip.style.padding = "5px";
    tooltip.style.borderRadius = "8px";
    tooltip.style.zIndex = "1000";
    tooltip.style.boxShadow = "1px 2px 2px 1px rgba(0, 0, 0, 0.14)";

    document.getElementById("vis-timeline").appendChild(tooltip);

    const timelineInfos = document.getElementById("vis-timeline")!.getBoundingClientRect();
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    if (item.classList && item.classList.contains("flag")) {
      // Position for flags
      const itemRect = item.getBoundingClientRect();
      tooltip.style.top = `${itemRect.bottom - timelineInfos.top + 10}px`;
      tooltip.style.left = `${itemRect.left - timelineInfos.left + itemRect.width / 2 - tooltipWidth / 2}px`;
    } else {
      // position for events
      const itemRect = document.getElementById(item.id).getBoundingClientRect();
      tooltip.style.top = `${itemRect.bottom - timelineInfos.top + tooltipHeight}px`;
      tooltip.style.left = `${itemRect.left - timelineInfos.left + itemRect.width / 2 - tooltipWidth / 2}px`;
    }
  }

  // Hide the tooltip when the mouse leaves an item
  private hideTooltip() {
    const tooltip = document.getElementById("tooltip");
    if (tooltip) {
      tooltip.remove();
    }
  }

  /*----END HANDLE EVENTS ROADMAP----*/

  /*----MANAGE EVENTS/GROUPS UTILS----*/
  openMenuEvent(event: RoadmapEvent, clickEvent) {
    this.currentEvent = event;
    const menuElement = document.getElementById("menuEventContainer");
    if (menuElement) {
      menuElement.style.left = `${clickEvent.pageX}px`;
      menuElement.style.top = `${clickEvent.pageY}px`;
      menuElement.style.position = `absolute`;
    }
    this.menuEventTrigger.openMenu();
  }

  openMenuGroup(group: RoadmapGroup, clickGroup) {
    this.currentGroup = group;
    const menuElement = document.getElementById("menuGroupContainer");
    if (menuElement) {
      menuElement.style.left = `${clickGroup.pageX}px`;
      menuElement.style.top = `${clickGroup.pageY}px`;
      menuElement.style.position = `absolute`;
    }
    this.menuGroupTrigger.openMenu();
  }

  openAddGroupDialog(): void {
    const dialogRef = this.dialog.open(AddGroupComponent, {
      data: {productId: this.productId},
      width: "min-content",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshRoadmap();
      }
    });
  }

  openAddEventDialog(): void {
    const dialogRef = this.dialog.open(AddEventComponent, {
      data: {productId: this.productId},
      width: "min-content",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshRoadmap();
      }
    });
  }

  public editEvent(eventId: string) {
    const eventIdNumber = parseInt(eventId.split("-")[1]);
    const groupWithEvent = this.groups.find((group) => group.events.some((event) => event.id === eventIdNumber));
    const eventToEdit = groupWithEvent.events.find((event) => event.id === eventIdNumber);
    this.dialog
      .open(AddEventComponent, {
        width: "min-content",
        data: {
          productId: this.productId,
          groupId: groupWithEvent.id,
          event: eventToEdit,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refreshRoadmap();
        }
      });
  }

  public deleteEvent(eventId: string) {
    const eventIdNumber = parseInt(eventId.split("-")[1]);
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete an event",
          text: "Are you sure you want to delete this event ?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "confirm") {
          this.roadmapService.deleteEvent(eventIdNumber).subscribe(
            () => {
              this.toastrService.success("Event has been deleted !");
              this.refreshRoadmap();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the event.");
            }
          );
        }
      });
  }

  public editGroup(groupId: string) {
    const groupIdNumber = parseInt(groupId.split("-")[1]);
    this.dialog
      .open(AddGroupComponent, {
        width: "min-content",
        data: {
          productId: this.productId,
          group: this.groups.find((group) => groupIdNumber === group.id),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refreshRoadmap();
        }
      });
  }

  public deleteGroup(groupId: string) {
    const groupIdNumber = parseInt(groupId.split("-")[1]);
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete a group",
          text: "Are you sure you want to delete this group and its events ?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "confirm") {
          this.roadmapService.deleteGroup(groupIdNumber).subscribe(
            () => {
              this.toastrService.success("Group has been deleted !");
              this.refreshRoadmap();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the group.");
            }
          );
        }
      });
  }

  /*----END MANAGE EVENTS/GROUPS UTILS----*/

  /*----MANAGE SEARCH----*/
  toggleSearch() {
    this.isSearchVisible = !this.isSearchVisible;
    if (!this.isSearchVisible) {
      this.clearSearch(); // Clear search and highlight if search is hidden
    } else {
      this.outsideClickListener = this.renderer.listen('window', 'click', (e: any) => {
        if ((document.getElementById("search-menu-button") == null ||
            !document.getElementById("search-menu-button").contains(e.target)) &&
          (document.getElementById("search-menu") == null ||
            !document.getElementById("search-menu").contains(e.target))) {
          this.closeSearch();
        }
      });
    }
  }

  closeSearch() {
    this.isSearchVisible = false;
    this.clearSearch();
  }

  // Function to clear search results and remove highlight
  clearSearch() {
    this.searchResults = [];
    this.searchQuery = "";
    this.removeHighlight();
  }

  // Function to perform search
  onSearchChange() {
    if (this.searchQuery.length >= 1) {
      // Start search after 3 characters
      this.searchResults = this.performSearch(this.searchQuery);
    } else {
      this.searchResults = [];
    }
  }

  // Function to perform search on groups, events, and countries
  performSearch(query: string): any[] {
    const results = [];

    // Search in groups
    const groupResults = this.groups.filter(
      (group) => group.name.toLowerCase().includes(query.toLowerCase()) || group.country?.toLowerCase().includes(query.toLowerCase())
    );
    results.push(...groupResults);

    // Search in events
    const eventResults = this.allEvents.filter((event) => event.name.toLowerCase().includes(query.toLowerCase()));
    results.push(...eventResults);

    return results;
  }

  onResultClick(result: any) {
    if (result.startDate && result.endDate) {
      this.focusOnItem(result);
    }
  }

  focusOnItem(item: any) {
    // Get the unique ID of the item
    const itemId = item.events ? `group-${item.id}` : `event-${item.id}`;

    // Get the central date of the item using its start and end dates
    const startDate = moment(item.startDate);
    const endDate = moment(item.endDate);
    const centerDate = startDate.add(endDate.diff(startDate) / 2).toDate();

    this.timeline.moveTo(centerDate, {animation: true}, () => {
      this.highlightItem(itemId);

      // Store the highlighted item's ID to reset it later
      this.focusedItemId = itemId;
    });
  }

  // Function to remove the highlighted item
  private removeHighlight() {
    if (this.focusedItemId) {
      const previousItem = document.querySelector(`#${this.focusedItemId}`);
      if (previousItem) {
        previousItem.parentElement.parentElement.parentElement.classList.remove("highlighted-event");
        previousItem.parentElement.parentElement.parentElement.classList.remove("highlighted-group");
      }
      this.focusedItemId = null;
    }
  }

  highlightItem(itemId: string) {
    // Reset highlight of the previous item
    this.removeHighlight();

    // Add highlight class to the current item
    const currentItem = document.querySelector(`#${itemId}`);
    if (currentItem && itemId.includes("group")) {
      currentItem.parentElement.parentElement.parentElement.classList.add("highlighted-group");
    }
    if (currentItem && itemId.includes("event")) {
      currentItem.parentElement.parentElement.parentElement.classList.add("highlighted-event");
    }
  }

  /*END MANAGE SEARCH*/

  extractChecklist(templateName: string) {
    this.roadmapService.extractChecklist(templateName).subscribe(
      (file) => {
        let resp: Blob = new Blob([file], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          endings: "transparent",
        });
        saveAs(resp, templateName + ".xlsx");
      },
      () => {
        this.toastrService.error("Failed to get the file " + templateName);
      }
    );
  }

  ngOnDestroy() {
    this.outsideClickListener();
  }
}
