<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "map" }}
          </span>
          {{ "Roadmap" }}
        </div>
      </button>
      <div class="container-right">
        <span class="last-update-text">Last Update : {{ lastUpdate | date : "dd/MM/yyyy":"UTC" }}</span>
        <div class="additional-btn" *ngIf="isWriter || isAdmin">
          <button type="button" class="btn btn-secondary" (click)="openAddGroupDialog()">
            <span class="material-icons-outlined" aria-hidden="true">add</span>Add Group
          </button>
        </div>
        <div class="additional-btn" *ngIf="isWriter || isAdmin">
          <button type="button" class="btn btn-primary" (click)="openAddEventDialog()">
            <span class="material-icons-outlined" aria-hidden="true">add</span>Add Event
          </button>
        </div>
        <div class="additional-btn">
          <button type="button" class="btn btn-secondary" [matMenuTriggerFor]="menuExtractChecklist">
            <span class="material-icons-outlined" aria-hidden="true">keyboard_arrow_down</span>
            Extract Checklist
          </button>
          <mat-menu #menuExtractChecklist="matMenu" yPosition="below">
            <button mat-menu-item (click)="extractChecklist('Template generic checklist')">Template generic checklist</button>
            <button mat-menu-item (click)="extractChecklist('Template Angola checklist')">Template Angola checklist</button>
          </mat-menu>
        </div>
        <div id="search-menu-button" class="additional-btn">
          <button class="btn btn-outline-primary btn-circle" (click)="toggleSearch()">
            <span class="material-icons-outlined">search</span>
          </button>
        </div>

        <div #searchContainer *ngIf="isSearchVisible" class="search-container" id="search-menu">
          <div class="search-input-container">
            <span class="material-icons-outlined search-icon">search</span>
            <input
              type="text"
              [(ngModel)]="searchQuery"
              (input)="onSearchChange()"
              placeholder="Search for group, event or country"
              class="search-input"/>
          </div>
          <ul *ngIf="searchResults.length > 0" class="search-results-list">
            <!-- Display Events -->
            <ng-container *ngIf="hasEvents">
              <li class="search-section-title">Event</li>
              <ng-container *ngFor="let result of searchResults">
                <li
                  class="search-section-element"
                  *ngIf="!result.events"
                  (click)="onResultClick(result)"
                  (keydown)="$event.key == 'Enter' ? onResultClick(result) : null">
                  {{ result.name }}
                </li>
              </ng-container>
            </ng-container>

            <!-- Display Groups -->
            <ng-container *ngIf="hasGroups">
              <li class="search-section-title">Group</li>
              <ng-container *ngFor="let result of searchResults">
                <li
                  class="search-section-element"
                  *ngIf="result.events"
                  (click)="onResultClick(result)"
                  (keydown)="$event.key == 'Enter' ? onResultClick(result) : null">
                  {{ result.name }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </h4>
    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse show" [attr.aria-labelledby]="'accordion-btn-header-' + section">
      <div class="accordion-body">
        <!-- roadmap.component.html -->
        <div class="legend-container">
          <div class="legend-element">
            <div class="legend-color"></div>
            <span class="legend-text">Deploy Action</span>
          </div>
          <div class="legend-element" *ngFor="let jiraLabel of jiraLabels">
            <div class="legend-color color-{{jiraLabel.name.toLowerCase()}}"></div>
            <span class="legend-text">{{jiraLabel.displayName}}</span>
          </div>
        </div>
        <div id="tooltip" class="tooltip"></div>
        <div id="menuEventContainer" class="menu-container" [matMenuTriggerFor]="menuEvent" #menuEventTrigger="matMenuTrigger">
          <mat-menu #menuEvent="matMenu">
            <button mat-menu-item class="menu-item" (click)="editEvent(currentEvent.id)">Edit Event</button>
            <button mat-menu-item class="menu-item" (click)="deleteEvent(currentEvent.id)">Delete Event</button>
          </mat-menu>
        </div>
        <div id="menuGroupContainer" class="menu-container" [matMenuTriggerFor]="menuGroup" #menuGroupTrigger="matMenuTrigger">
          <mat-menu #menuGroup="matMenu">
            <button mat-menu-item class="menu-item" (click)="editGroup(currentGroup.id)">Edit Group</button>
            <button mat-menu-item class="menu-item" (click)="deleteGroup(currentGroup.id)">Delete Group</button>
          </mat-menu>
        </div>
        <button mat-button [matMenuTriggerFor]="menuEvent" #menuEventTrigger="matMenuTrigger" style="display: none"></button>
        <button mat-button [matMenuTriggerFor]="menuGroup" #menuGroupTrigger="matMenuTrigger" style="display: none"></button>
        <div id="vis-timeline"></div>
      </div>
    </div>
  </div>
</div>
