import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {UserDTO} from "../common/user.service";

export interface Product {
  id: number;
  name: string;
  logo: string;
  productOwners: UserDTO[];
  branches: string[];
  affiliates: string[];
  sponsors: UserDTO[];
  domainLeads: UserDTO[];
  domain: string | null;
  itScaler: UserDTO;
  squadProfile: string | null;
  statusCreationDate: Date | null;
  statusTransferDate: Date | null;
  statusRunDate: Date | null;
}

@Injectable({
  providedIn: "root",
})
export class ProductDetailsService {
  private apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/products`;
  constructor(private http: HttpClient) {}

  getProductDetails(productId: number): Observable<Product> {
    return this.http.get<Product>(`${this.apiUrl}/${productId}`);
  }

  updateProductDetails(id: number, details: Product): Observable<any> {
    return this.http.post(`${this.apiUrl}/update/${id}`, details);
  }
}
