import {Component} from "@angular/core";
import {AuthenticationService} from "../common/authentication.service";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent {
  constructor(public authenticationService: AuthenticationService) {}

  dismissAlert() {
    this.authenticationService.showAlert = false;
  }
}
