import {Component, Input} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "popup-header",
    templateUrl: "./popup-header.component.html",
    styleUrls: ["./popup-header.component.scss"],
})
export class PopupHeaderComponent {
    @Input() dialogRef: MatDialogRef<any>;
    @Input() icon: string;
    @Input() text: string;

    closePopup() {
        this.dialogRef.close();
    }
}

