import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";
import {ToastrService} from "ngx-toastr";
import {RoadmapEvent, RoadmapEventHistory, RoadmapService} from "../common/roadmap.service";

@Component({
  selector: "app-add-event",
  templateUrl: "./add-event.component.html",
  styleUrls: ["./add-event.component.scss"],
})
export class AddEventComponent implements OnInit, OnDestroy {
  addEventForm: FormGroup;
  productId: number;
  groups_list: {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
  }[];
  minStartDate: Date | null = null;
  maxEndDate: Date | null = null;
  title: string;
  isEditMode: boolean;
  eventHistory: RoadmapEventHistory[] = [];
  saveInProgress: boolean = false;

  intervalCheckRange: any;

  constructor(
    private fb: FormBuilder,
    private roadmapService: RoadmapService,
    public dialogRef: MatDialogRef<AddEventComponent>,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: number; groupId: number; event?: RoadmapEvent }
  ) {
    this.productId = this.data.productId;

    // Determine if we're in edit mode
    this.isEditMode = !!this.data.event;
    this.title = this.isEditMode ? "Edit Event" : "Create New Event";

    // Initialize form with blank fields or existing event data
    this.addEventForm = this.fb.group({
      roadmapGroupId: [0, Validators.required], // Group ID
      name: ["", Validators.required], // Event name
      type: ["Mobilisation LBUs"], // Event type
      priority: ["Low"], // Event priority
      startDate: [new Date().toISOString(), Validators.required],
      endDate: [new Date().toISOString(), Validators.required],
      status: ["Ready"], // Event status
      comment: [""], // Event comment
      creationDate: [new Date().toISOString()], // Creation date
    });

    // If edit mode, pre-fill the form with event data
    if (this.isEditMode && this.data.event) {
      this.addEventForm.patchValue({
        roadmapGroupId: this.data.groupId,
        name: this.data.event.name,
        type: this.data.event.type,
        priority: this.data.event.priority,
        startDate: moment(this.data.event.startDate).toISOString(),
        endDate: moment(this.data.event.endDate).toISOString(),
        status: this.data.event.status,
        comment: this.data.event.comment,
        creationDate: moment(this.data.event.creationDate).toISOString(),
      });
      this.fetchEventHistory(this.data.event.id);
    }
  }

  ngOnInit(): void {
    // Fetch roadmap groups and set constraints on dates
    this.roadmapService.getRoadmapGroupsListByProductId(this.productId).subscribe(
      (groups) => {
        this.groups_list = groups;

        // Set group ID for new events and initialize date constraints for edit mode
        if (this.groups_list.length > 0) {
          if (!this.isEditMode) {
            this.addEventForm.patchValue({
              roadmapGroupId: this.groups_list[0].id,
            });
          }
          this.updateDateConstraints(this.addEventForm.controls["roadmapGroupId"].value);
        }
      },
      (error) => {
        console.error("Error while fetching groups: ", error);
      }
    );

    // Update date constraints whenever the selected group changes
    this.addEventForm.controls["roadmapGroupId"].valueChanges.subscribe((groupId) => {
      this.updateDateConstraints(parseInt(groupId));
    });

    /** Fixing weird bug where the date range is considered invalid when the start date is modified before the end date **/
    this.intervalCheckRange = setInterval(() => {
      this.addEventForm.controls["startDate"].updateValueAndValidity();
      this.addEventForm.controls["endDate"].updateValueAndValidity();
    }, 1000);
  }

  private fetchEventHistory(eventId: number): void {
    this.roadmapService.getEventHistory(eventId).subscribe((eventHistory) => {
      this.eventHistory = eventHistory;
    });
  }

  private updateDateConstraints(groupId: number): void {
    const selectedGroup = this.groups_list.find((group) => group.id === groupId);
    if (selectedGroup) {
      this.minStartDate = new Date(selectedGroup.startDate);
      this.maxEndDate = new Date(selectedGroup.endDate);
      if (!this.isEditMode) {
        this.addEventForm.patchValue({
          startDate: this.minStartDate,
          endDate: this.minStartDate,
        });
      }
    }
  }

  onSubmit(): void {
    if (this.addEventForm.valid) {
      const formValue = this.addEventForm.value;

      const eventToSave: RoadmapEvent = {
        id: this.isEditMode ? this.data.event.id : 0, // Use existing ID in edit mode
        roadmapGroup: {id: formValue.roadmapGroupId}, // Group ID
        name: formValue.name,
        type: formValue.type,
        priority: formValue.priority,
        startDate: formValue.startDate,
        endDate: formValue.endDate,
        comment: formValue.comment,
        status: formValue.status,
        creationDate: formValue.creationDate,
      };
      this.saveInProgress = true;
      this.roadmapService.saveEvent(eventToSave, this.data.event?.id).subscribe((res) => {
        this.dialogRef.close(true);
        if (res) {
          this.saveInProgress = false;
          this.toastrService.success("Event has been saved !");
        } else {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the event.");
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpload(): void {
    console.log("Upload file");
  }

  ngOnDestroy() {
    clearInterval(this.intervalCheckRange);
  }
}
