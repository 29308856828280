import {HttpClient} from "@angular/common/http";
import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Observable, Subject, concat, of} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap} from "rxjs/operators";
import {UserDTO, UserService} from "../user.service";

@Component({
  selector: "app-user-autocomplete",
  templateUrl: "./user-autocomplete.component.html",
})
export class UserAutocompleteComponent {
  @Input() placeholder: string = "Search";
  @Input() multiple: boolean = false;
  @Input() initialSelectedUsers: UserDTO[] = [];
  @Output() onUserSelected = new EventEmitter<UserDTO[]>();
  selectedUsers: UserDTO[] = [];

  editForm: FormGroup;
  filteredUsers: UserDTO[] = [];
  users$: Observable<UserDTO[]>;
  usersInput$ = new Subject<string>();
  usersLoading = false;
  selectedUser: UserDTO;
  minLengthTerm = 1;

  constructor(private fb: FormBuilder, private http: HttpClient, private userService: UserService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.multiple) {
      this.selectedUsers = [...this.initialSelectedUsers];
    } else {
      this.selectedUser = this.initialSelectedUsers.length > 0 ? this.initialSelectedUsers[0] : null;
    }
    this.setupTypeahead();
  }

  get model() {
    return this.multiple ? this.selectedUsers : this.selectedUser;
  }

  set model(value: UserDTO[] | UserDTO) {
    if (this.multiple) {
      this.selectedUsers = value as UserDTO[];
    } else {
      this.selectedUser = value as UserDTO;
    }
  }
  setupTypeahead() {
    this.users$ = concat(
      of([]),
      this.usersInput$.pipe(
        filter((term) => term !== null && term.length >= this.minLengthTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.usersLoading = true)),
        switchMap((term) =>
          this.userService.searchUsers(term).pipe(
            catchError(() => of([])),
            tap(() => (this.usersLoading = false))
          )
        )
      )
    );
  }
}
