import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsKey,
        enableAutoRouteTracking: true,
        maxBatchInterval: 0,
        disableFetchTracking: false
      }
    });
    this.appInsights.loadAppInsights();
  }

  setAuthenticatedUser(authenticatedUserId: string, accountId: string) {
    this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, true);
  }

}