import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {SharedEventsService} from "../common/shared-events-service";
import {UserService} from "../common/user.service";
import {Product} from "../product-details/product-details.service";
@Component({
  selector: "app-search-product",
  templateUrl: "./search-product.component.html",
  styleUrls: ["./search-product.component.scss"],
})
export class SearchProductComponent implements OnInit, OnChanges {
  @Input() searchQuery: string;

  products: Product[] = [];
  filteredProducts: Product[] = [];

  productNameFilter: string;
  productOwnerFilter: string;
  branchFilter: string;
  affiliateFilter: string;
  domainFilter: string;
  squadFilter: string;

  productNameOptions: string[] = [];
  productOwnerOptions: string[] = [];
  branchOptions: string[] = [];
  affiliateOptions: string[] = [];
  domainOptions: string[] = [];
  squadOptions: string[] = [];

  itemsPerPage: number = 10;
  currentPage: number = 1;
  paginatedProducts: Product[] = [];
  totalPages: number = 10;

  constructor(
    private productService: UserService,
    private toastrService: ToastrService,
    private sharedEventsService: SharedEventsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadProducts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchQuery?.currentValue) {
      this.filterProducts();
    }
  }

  loadProducts(): void {
    this.productService.getProducts().subscribe(
      (data) => {
        this.products = data;
        this.filteredProducts = [...this.products];
        this.extractUniqueOptions();
        this.updatePagination();
      },
      (error) => {
        this.toastrService.error("Error when loading the products");
      }
    );
  }

  extractUniqueOptions(): void {
    const productNames = new Set<string>();
    const productOwners = new Set<string>();
    const branches = new Set<string>();
    const affiliates = new Set<string>();
    const domains = new Set<string>();
    const squads = new Set<string>();

    this.products.forEach((product) => {
      if (product.name) productNames.add(product.name);
      if (product.productOwners) {
        product.productOwners.forEach((owner) => productOwners.add(owner.fullName));
      }

      if (product.branches) {
        product.branches.forEach((branch) => branches.add(branch));
      }

      if (product.affiliates) {
        product.affiliates.forEach((affiliate) => affiliates.add(affiliate));
      }

      if (product.domain) domains.add(product.domain);

      if (product.squadProfile) squads.add(product.squadProfile);
    });

    this.productNameOptions = Array.from(productNames);
    this.productOwnerOptions = Array.from(productOwners);
    this.branchOptions = Array.from(branches);
    this.affiliateOptions = Array.from(affiliates);
    this.domainOptions = Array.from(domains);
    this.squadOptions = Array.from(squads);
  }

  filterProducts(): void {
    this.filteredProducts = this.products.filter((product) => {
      return (
        (this.productNameFilter ? product.name?.toLowerCase().includes(this.productNameFilter.toLowerCase()) : true) &&
        (this.productOwnerFilter
          ? product.productOwners?.some((owner) => owner.fullName.toLowerCase().includes(this.productOwnerFilter.toLowerCase()))
          : true) &&
        (this.branchFilter ? product.branches?.some((branch) => branch.toLowerCase().includes(this.branchFilter.toLowerCase())) : true) &&
        (this.affiliateFilter
          ? product.affiliates?.some((affiliate) => affiliate.toLowerCase().includes(this.affiliateFilter.toLowerCase()))
          : true) &&
        (this.domainFilter ? product.domain?.toLowerCase().includes(this.domainFilter.toLowerCase()) : true) &&
        (this.squadFilter ? product.squadProfile?.toLowerCase().includes(this.squadFilter.toLowerCase()) : true)
      );
    });
    this.updatePagination();
  }

  resetFilters(): void {
    this.productNameFilter = null;
    this.productOwnerFilter = null;
    this.branchFilter = null;
    this.affiliateFilter = null;
    this.domainFilter = null;
    this.squadFilter = null;
    this.searchQuery = null;
    this.filteredProducts = [...this.products];

    this.updatePagination();
  }

  get upperLimit(): number {
    return Math.min(this.currentPage * this.itemsPerPage, this.filteredProducts.length);
  }

  get lowerLimit(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  updatePagination(): void {
    this.totalPages = Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    this.currentPage = 1;
    this.paginateProducts();
  }

  paginateProducts(): void {
    const start = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
    const end = start + Number(this.itemsPerPage);
    this.paginatedProducts = this.filteredProducts.slice(start, end);
  }

  changePage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.paginateProducts();
  }

  addToFavorites(productId: number) {
    this.productService.addFavorite(productId).subscribe(
      (response) => {
        this.toastrService.success("Product added to favorites.");
        this.loadProducts();
        this.sharedEventsService.notifyReloadHeader();
      },
      (error) => {
        this.toastrService.error("Failed to add product to favorites.");
      }
    );
  }

  removeFromFavorites(productId: number) {
    this.productService.removeFavorite(productId).subscribe(
      (response) => {
        this.toastrService.success("Product removed from favorites.");
        this.loadProducts();
        this.sharedEventsService.notifyReloadHeader();
      },
      (error) => {
        this.toastrService.error("Failed to remove product from favorites.");
      }
    );
  }

  toggleFavorite(productId: number, isFavorite: boolean) {
    if (isFavorite) {
      this.removeFromFavorites(productId);
    } else {
      this.addToFavorites(productId);
    }
  }

  redirectToProduct(product: Product): void {
    this.sharedEventsService.setSelectedProduct(product);
    this.router.navigate(["/product", product.name, product.id]);
  }

  getFormattedProductOwners(product: Product): string {
    return product.productOwners?.map((owner) => owner.fullName).join(" & ");
  }
}
