import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {Highlight, HighlightRes, HighlightService} from "./highlights.service";

@Component({
  selector: "app-highlights",
  templateUrl: "./highlights.component.html",
})
export class HighlightsComponent implements OnInit, OnChanges {
  @Input() productId!: number;
  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;

  highlights: Highlight[] = [];
  lastUpdateHighlights!: Date;

  isAdding: boolean = false;
  newHighlight: Highlight = this.resetNewHighlight();
  saveInProgress: boolean = false;

  constructor(private highlightService: HighlightService, private dialog: MatDialog, private toastrService: ToastrService) {}

  ngOnInit(): void {
    if (this.productId) {
      this.loadHighlights();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productId?.currentValue) {
      this.loadHighlights();
    }
  }

  loadHighlights(): void {
    this.highlightService.getHighlightsByProductId(this.productId).subscribe(
      (data: HighlightRes) => {
        this.highlights = data.highlights.map((highlight) => ({
          ...highlight,
          description: highlight.description,
          date: new Date(highlight.creationDate),
        }));
        this.lastUpdateHighlights = data.highlightLastUpdateDate;
      },
      () => {
        this.toastrService.error("Error loading highlights.");
      }
    );
  }

  startAdding(): void {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "help", // Question icon
          title: "Prefill Description?",
          text: "Do you want to prefill the description with the last highlight?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "confirm") {
          // Prefill with the most recent description
          const recentDescription = this.highlights.length > 0 ? this.highlights[0].description : "";
          this.newHighlight = {
            id: 0,
            description: recentDescription,
            creationDate: new Date(),
            product: {id: this.productId},
          };
        } else {
          // Do not prefill
          this.newHighlight = this.resetNewHighlight();
        }
        this.isAdding = true;
      });
  }

  saveNewHighlight(): void {
    if (!this.newHighlight.description.trim()) {
      this.toastrService.error("Description cannot be empty.");
      return;
    }
    this.saveInProgress = true;

    this.highlightService.addHighlight(this.newHighlight).subscribe(
      (createdHighlight) => {
        this.saveInProgress = false;
        this.toastrService.success("Highlight added successfully!");
        this.highlights.unshift(createdHighlight); // Add at the top of the list
        this.isAdding = false;
        this.loadHighlights();
      },
      () => {
        this.saveInProgress = false;
        this.toastrService.error("Error adding highlight.");
      }
    );
  }

  cancelAdding(): void {
    this.isAdding = false;
  }

  startEditing(highlight: Highlight): void {
    highlight.isEditing = true;
    highlight.originalDescription = highlight.description;
  }

  saveEditedHighlight(highlight: Highlight): void {
    if (!highlight.description.trim()) {
      this.toastrService.error("Description cannot be empty.");
      return;
    }
    this.saveInProgress = true;

    this.highlightService.updateHighlight(highlight).subscribe(
      () => {
        this.saveInProgress = false;
        this.toastrService.success("Highlight updated successfully!");
        highlight.isEditing = false;
        this.lastUpdateHighlights = new Date();
      },
      () => {
        this.saveInProgress = false;
        this.toastrService.error("Error updating highlight.");
      }
    );
  }

  cancelEditing(highlight: Highlight): void {
    highlight.isEditing = false;
    highlight.description = highlight.originalDescription || highlight.description;
    highlight.originalDescription = undefined;
  }

  deleteHighlight(highlightId: number): void {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete Highlight",
          text: "Are you sure you want to delete this highlight?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "confirm") {
          this.highlightService.deleteHighlight(highlightId).subscribe(
            () => {
              this.toastrService.success("Highlight deleted successfully!");
              this.highlights = this.highlights.filter((h) => h.id !== highlightId);
              this.loadHighlights();
            },
            () => {
              this.toastrService.error("Error deleting highlight.");
            }
          );
        }
      });
  }

  private resetNewHighlight(): Highlight {
    return {
      id: 0,
      description: "",
      creationDate: new Date(),
      product: {id: this.productId},
    };
  }

  formatDescription(description: string): string {
    return description.replace(/\n/g, "<br>");
  }
}
