<div class="product-container">
  <!-- Accordions for product details and roadmap -->
  <div class="accordion-container">
    <!-- Display product accordion section -->
    <app-product-details
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [productName]="productName"
      [isWriter]="isWriter.includes('Product Details')"
      [section]="toIdFormat(productName)"></app-product-details>

    <!-- Display highlights accordion section -->
    <app-highlights
      [isAdmin]="isAdmin"
      [productId]="productId"
      [isWriter]="isWriter.includes('Highlights')"
      [section]="toIdFormat('Highlights')"></app-highlights>

    <!-- Display roadmap accordion section -->
    <app-roadmap
      [isAdmin]="isAdmin"
      [productId]="productId"
      [isWriter]="isWriter.includes('Roadmap')"
      [section]="toIdFormat('Roadmap')"></app-roadmap>

    <!-- Display the 8 pillars accordion section -->
    <eight-pillars
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [selectedProductName]="productName"
      [isWriter]="isWriter.includes('8 Pillars')"
      [section]="toIdFormat('8 Pillars')"></eight-pillars>

    <focus
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [isWriter]="isWriter.includes('Focus')"
      [section]="toIdFormat('Focus')"></focus>

    <!-- Display usage accordion section -->
    <app-usage [productId]="productId" [productName]="productName"></app-usage>
    
    <toolbox
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [isWriter]="isWriter.includes('Toolbox')"
      [section]="toIdFormat('Toolbox')"></toolbox>
  </div>
</div>
