<popup-header [dialogRef]="dialogRef" [icon]="deleteMode ? 'delete' : 'handyman'" text="{{ popupTitle }}"></popup-header>
<div class="popup-container">
  <div class="segmented-control-content">
    <div class="form-group">
      <select class="form-select" [(ngModel)]="selectedItem">
        <option [value]="{'title': 0}" disabled selected>Choose the {{type}} to {{deleteMode ? "delete" : "edit"}}</option>
        <option *ngFor="let item of existingItems" [ngValue]="item">{{item.title}}</option>
      </select>
    </div>

    <div class="form-group item-container" *ngIf="type == 'link' && !deleteMode">
      <input type="text" class="form-control" placeholder="Link" [(ngModel)]="selectedItem.link"/>
    </div>

    <div class="item-container"
         dndDropzone
         (dndDrop)="onDrop($event)"
         [dndAllowExternal]="true"
         *ngIf="type == 'document' && !deleteMode">
      <form class="upload-file">
        <input id="upload-input1" type="file" name="fileUpload" (change)="getFile($event)"/>

        <label for="upload-input1" class="upload-label align-items-center">
          <span class="btn btn-primary">
            <span class="material-icons" aria-hidden="true">cloud_upload</span> Select file
          </span>
          <span class="ms-3">...or drag and drop file here</span>
        </label>
        <div class="uploaded-name" *ngIf="selectedItem.documentPath">Uploaded file : {{selectedItem.documentPath.split("/")[selectedItem.documentPath.split("/").length - 1]}}</div>
      </form>
    </div>
  </div>

  <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>

  <div class="bottom-popup">
    <div class="buttons-container">
      <button type="button" class="btn btn-secondary" (click)="closePopup()">Cancel</button>
      <button *ngIf="!deleteMode" type="button" class="btn btn-primary" (click)="save()" [disabled]="saveInProgress">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saveInProgress"></span>
        Save
      </button>
      <button *ngIf="deleteMode" type="button" class="btn btn-primary" (click)="delete()" [disabled]="saveInProgress">Delete</button>
    </div>
  </div>
</div>
