<popup-header [dialogRef]="dialogRef" icon="grading" text="{{ title }}"></popup-header>
<div class="popup-container">
  <div class="fields-container">
    <div class="form-group">
      <label for="textareaInput" class="form-label">Comment</label>
      <textarea class="form-control" id="textareaInput" rows="3" [(ngModel)]="comment" placeholder="Write the comment"></textarea>
    </div>
  </div>

  <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>

  <div class="bottom-popup">
    <div class="dot-container">
      <button class="dot dot-green" [ngClass]="{selected: selectedStatus === 'green'}" (click)="selectedStatus = 'green'"></button>
      <button class="dot dot-orange" [ngClass]="{selected: selectedStatus === 'orange'}" (click)="selectedStatus = 'orange'"></button>
      <button class="dot dot-red" [ngClass]="{selected: selectedStatus === 'red'}" (click)="selectedStatus = 'red'"></button>
    </div>

    <div class="buttons-container">
      <button type="button" class="btn btn-secondary" (click)="closePopup()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveComment()" [disabled]="saveInProgress">Validate</button>
    </div>
  </div>
</div>
