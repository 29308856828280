import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {ProductDTO} from "./user.service";

@Injectable({
  providedIn: "root",
})
export class SharedEventsService {
  private reloadHeaderSubject = new Subject<void>();
  private selectedProductSubject = new BehaviorSubject<ProductDTO | null>(null);

  reloadHeader$ = this.reloadHeaderSubject.asObservable();
  selectedProduct$ = this.selectedProductSubject.asObservable();

  notifyReloadHeader() {
    this.reloadHeaderSubject.next();
  }

  setSelectedProduct(product: ProductDTO): void {
    this.selectedProductSubject.next(product);
  }
}
