import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {saveAs} from "file-saver";
import {ToastrService} from "ngx-toastr";
import {ToolboxService} from "../toolbox/toolbox.service";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: "focus",
  templateUrl: "./focus.component.html",
  styleUrls: ["./focus.component.scss"],
})
export class FocusComponent implements OnChanges {
  @Input() selectedProductId!: number;

  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;
  @Input() section!: string;

  focus: any;

  saveInProgress: boolean;
  errorMessage: string;

  constructor(private toolboxService: ToolboxService, private toastrService: ToastrService, private dialog: MatDialog) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProductId?.currentValue) {
      this.loadFocus();
    }
  }

  loadFocus() {
    this.toolboxService.loadFocus(this.selectedProductId).subscribe((res) => {
      this.focus = res.result;
    });
  }

  treatFile(file) {
    if (file.size > 100000000) {
      this.errorMessage = "Uploaded file is too large (> 100MB)."
    } else {
      this.saveFocus(file);
    }
  }

  getFile($event) {
    this.treatFile($event.target.files[0]);
  }

  saveFocus(file) {
    this.saveInProgress = true;
    this.toolboxService.saveFocus(this.selectedProductId, file).subscribe(() => {
      this.saveInProgress = false;
      this.toastrService.success("Document has been saved !");
      this.loadFocus();
    }, () => {
      this.saveInProgress = false;
      this.toastrService.error("An error occurred while saving the document.");
    });
  }

  deleteFocus() {
    this.dialog.open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete document",
          text: "Are you sure you want to delete the document ?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "confirm") {
          this.saveInProgress = true;
          this.toolboxService.deleteFocus(this.selectedProductId).subscribe(() => {
            this.saveInProgress = false;
            this.toastrService.success("Document has been deleted !");
            this.loadFocus();
          }, () => {
            this.saveInProgress = false;
            this.toastrService.error("An error occurred while deleting the document.");
          });
        }
      });
  }

  getDocument(document: any) {
    let filename = document.split("/")[document.split("/").length - 1];

    this.toolboxService.getDocument(document).subscribe(
      (file) => {
        let resp: Blob = new Blob([file], {
          endings: "transparent",
        });

        saveAs(resp, filename);
      },
      () => {
        this.toastrService.error("Failed to get the document");
      }
    );
  }
}
